import React, { Fragment, useState ,useEffect} from "react";
import Breadcrumb from "../../layout/breadcrumb";
import {  Row, Col, Card, CardBody, CardHeader, Button, Modal, ModalBody, ModalFooter, Form,
  ModalHeader, Label, Input, FormGroup, FormFeedback } from "reactstrap";
import { Container } from "react-bootstrap";
import DataTable from "react-data-table-component";
import SweetAlert from 'sweetalert2'
import { useLocation, useNavigate } from "react-router";
import { API_BASE_URL } from "../../api/url";
import { classes } from "../../data/layouts";
import adminServices from "../../api/adminService";
import Loading from "./Loading";
import { toast } from "react-toastify";



const Equipments = () => {

  const [VaryingContentone, setVaryingContentone] = useState(false);
  const [selectedItem, setSelectedItem] = useState("");
  const [title, setTitle] = useState("");
  const [error, setError] = useState("");
  const [description, setDescription] = useState("");
  const [selectedIcon, SetSelectedIcon] = useState("");
  const [loader, setLoader] = useState(true)

  const location = useLocation();
  const categoryData = location?.state;

  // const data = categoryData?.equipments;
  const [data,setData]= useState([])
    console.log("categoryData", categoryData);

  console.log("props", data);
//  const AddCategory = data?.map((i) => i.equipment);

  const history = useNavigate();
  const defaultLayoutObj = classes.find(
    (item) => Object.values(item).pop(1) === "compact-wrapper"
  );
  const layout =
    localStorage.getItem("layout") || Object.keys(defaultLayoutObj).pop();

  const onUpdateEquipments = (row) => { displayModel(row)
    // history(`${process.env.PUBLIC_URL}/app/equip/${row._id}`, {
    //   state: { equipment: row, isEdit: true, category: categoryData },
    // });
  };

     async function getEquipments() {
      setLoader(true)
       const res = await adminServices.getEquipments(categoryData._id);
       if (res.status) {
        setLoader(false)
         setData(res.data);
       } else {
         setData("");
       }
     }
     useEffect(() => {
       getEquipments();
     }, []);

  const tableColumns = [
    {
      name: "ID",
      selector: (row) => row._id,
      sortable: true,
    },
    {
      name: "Title",
      selector: (row) => row.title,
      sortable: true,
    },
    {
      name: "Helpers",
      selector: (row) => row.required_helpers,
      sortable: true,
      center: true,
    },
    {
      name: "Icon",
      selector: (row) => {
        if (row.icon) {
          return <img width={50} height={50} src={API_BASE_URL + row.icon} />;
        } else {
          return (
            <img
              width={50}
              height={50}
              src={require("../../assets/images/Icons/logo.png")}
            />
          );
        }
      },
      sortable: true,
    },
    {
      name: "Description",
      selector: (row) => row.description,
      sortable: true,
      center: true,
    },
    {
      name: "Action",
      selector: (row) => {
        return (
          <div>
            <span>
              <i
                className="fa fa-pencil"
                style={{
                  width: 35,
                  fontSize: 16,
                  padding: 11,
                  color: "rgb(40, 167, 69)",
                }}
                onClick={() => onUpdateEquipments(row)}
              ></i>
            </span>
            <span>
              <i
                className="fa fa-trash"
                style={{
                  width: 35,
                  fontSize: 16,
                  padding: 11,
                  color: "#e4566e",
                }}
                onClick={() => onRemoveEquipment(row)}
              ></i>
            </span>
          </div>
        );
      },
      sortable: true,
      center: true,
    },
  ];

  const onRemoveEquipment = (row) => {
    SweetAlert.fire({
      title: "Are you sure?",
      text: "Once deleted, you will not be able to recover this user!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Ok",
      cancelButtonText: "cancel",
      reverseButtons: true,
    }).then((result) => {
      if (result.value) {
        adminServices.updateEquipment(row._id, { ...row, status: false });
        SweetAlert.fire(
          "Deleted!",
          "Your Category has been deleted.",
          "success"
        );
        getEquipments()
      } else {
        SweetAlert.fire("Your file is safe!");
      }
    });
  };

     
  const displayModel = (row) => {
    if (row) {
      setVaryingContentone(!VaryingContentone);
      SetSelectedIcon(API_BASE_URL + row.icon)
      setSelectedItem(row);
      setTitle(row.title);
      setDescription(row.description);
    } else {
      setVaryingContentone(!VaryingContentone);
      SetSelectedIcon("");
      setTitle("");
      setDescription("");
    }    
  }


  const onSave = async () => {


    if (!title) {
      setError("Please enter the Title");
      return;
    }

    const formData = new FormData();
    formData.append("title", title);
    formData.append("icon", selectedIcon);
    formData.append("description", description);
    formData.append("status", true);


    if (selectedItem) {
    if (!title) {
      setError("Please enter the Title");
      return;
    }

      const res = await adminServices.updateEquipment(
        selectedItem._id, 
        formData)
      if (res.status) {


        toast(res.message);
        getEquipments();
        SetSelectedIcon("");
        setTitle("");
        setDescription("");
        displayModel();
      } else {
        toast.error(res.message);
      }

    } else {
      const res = await adminServices.addEquipment(
        categoryData._id, 
        formData);


      if (res.status) {
        toast(res.message);
        getEquipments();
        SetSelectedIcon("");
        setTitle("");
        setDescription("");
        displayModel()

      } else {
        toast.error(res.message)
      }
    }

  }




  let icon = ""

  if (selectedItem) {
    if (selectedIcon && typeof selectedIcon != 'string') {
      icon = URL.createObjectURL(selectedIcon);
    } else {
      icon = selectedIcon;
    }
  } else if (selectedIcon) {
    icon = URL.createObjectURL(selectedIcon);
  }


  return (
    <Fragment>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "flex-end",
        }}
      >
        <Breadcrumb parent="Table" title="Equipments" />
        <Button
          style={{ width: "8%", height: "10%", marginTop: "2%" }}
          color="primary"
          onClick={() =>  displayModel()
            // history(`${process.env.PUBLIC_URL}/app/equip/${layout}`, {
            //   state: { category: categoryData, isEdit: false },
            // })
          }
        >
          {"Add"}
        </Button>
      </div>

    {loader ? <Loading /> :  <Container fluid={true}>
        <Row>
          <Col sm="12">
            <Card>
              <Modal isOpen={VaryingContentone} toggle={() => displayModel()}>
                <ModalHeader toggle={() => displayModel()}>
                  {"Equipment"}
                </ModalHeader>
                <ModalBody>
                  <Form>
                    <FormGroup>
                      <Label className="col-form-label" for="recipient-name">{"Title:"}</Label>
                      <Input className="form-control"
                        type="text" value={title} defaultValue={""}
                        onChange={(e) => {
                          setTitle(e.target.value)
                          setError("")
                        }}
                        valid={error}
                        invalid={error}
                      />

                      <FormFeedback>{error}</FormFeedback>

                    </FormGroup>
                    <FormGroup>
                      <Label className="col-form-label" for="message-text">{"Description:"}</Label>
                      <Input type="textarea" className="form-control" id="message-text"
                        value={description} defaultValue={""}
                        onChange={(event) => setDescription(event.target.value)}></Input>
                    </FormGroup>
                    <FormGroup>
                      <Label className="col-form-label" for="recipient-name">{"Icon:"}</Label>


                      <img
                        width={100}
                        height={100}
                        style={{ marginLeft: 10 }}
                        src={icon}

                      />

                      <input
                        style={{ marginTop: 10 }}
                        onChange={(e) => {
                          // setUploadImg(true);
                          SetSelectedIcon(e.target.files[0]);

                        }}
                        // value={selectedIcon}
                        defaultValue={""}
                        accept="image/*"
                        className="form-control" type="file" />
                    </FormGroup>

                  </Form>
                </ModalBody>
                <ModalFooter>
                  <Button color="secondary" onClick={() => displayModel()}>
                    {"Close"}
                  </Button>
                  <Button color="primary" onClick={() => onSave()}>
                    {"Save"}
                  </Button>
                </ModalFooter>
              </Modal>

              <CardBody>
                <DataTable data={data} columns={tableColumns} center={true} />
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>}
    </Fragment>
  );
}
export default Equipments;