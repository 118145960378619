import React, { useState } from "react";
import { useEffect } from "react";
import adminServices from "../../api/adminService";

import {
  Row,
  Col,
  Card,
  CardBody,
  Button,
  Modal,
  ModalBody,
  Form,
  ModalHeader,
  Input,
} from "reactstrap";
import { Container } from "react-bootstrap";
import DataTable from "react-data-table-component";
import SweetAlert from "sweetalert2";
import { useNavigate } from "react-router";
import Breadcrumb from "../../layout/breadcrumb";
import { classes } from "../../data/layouts";
import { Fragment } from "react";
import { CiExport } from "react-icons/ci";

import Select from "react-select";
import Loading from "./Loading";
import moment from "moment/moment";

const options = [
  { value: "all", label: "All" },
  { value: "Pending", label: "Pending" },
  { value: "Approved", label: "Approved" },
  { value: "Draft", label: "Draft" },
  { value: "Spam", label: "Spam" },
  { value: "Rejected", label: "Rejected" },
];

const ListingData = () => {
  const [data, setData] = useState("");
  const [loader, setLoader] = useState(false);

  const history = useNavigate();
  const defaultLayoutObj = classes.find(
    (item) => Object.values(item).pop(1) === "compact-wrapper"
  );
  const layout =
    localStorage.getItem("layout") || Object.keys(defaultLayoutObj).pop();

  const [status, setStatus] = useState("all");
  const [VaryingContentone, setVaryingContentone] = useState(false);

  const [selectedListing, setSelectedListing] = useState([]);

  const [page, setPage] = useState(1);
  const [pageItems, setPageItems] = useState(10);
  const [totalItems, setTotalItems] = useState(0);

  const handler = async (id) => {
    // let qryString = "";
    // qryString = new URLSearchParams({ userId: id }).toString();

    const response = await adminServices.excelListing({});

    const href = URL.createObjectURL(response);
    const link = document.createElement("a");
    link.href = href;
    link.setAttribute(
      "download",
      `listing_${moment().format("YYYY-MM-DD-HH-mm")}`
    ); //or any other extension
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
    URL.revokeObjectURL(href);
  };

  async function getList() {
    let qryString = "";
    var filter = {
      items: pageItems,
      page: page > 0 ? page - 1 : 0,
    };
    if (status !== "all") {
      filter.status = status;
    }

    qryString = new URLSearchParams(filter).toString();
    setLoader(true);
    const res = await adminServices.getList(qryString);
    if (res.status) {
      setLoader(false);
      setData(res.data.data);
      setTotalItems(res.data.count);
    } else {
      setData([]);
    }
  }
  useEffect(() => {
    getList();
  }, [status, page, pageItems]);

  const onUpdateUser = (row) => {
    const userId = row.user_id;
    history(`${process.env.PUBLIC_URL}/app/listing/${layout}`, {
      state: { update: row, user: userId },
    });
  };

  const onRemoveListing = (id) => {
    SweetAlert.fire({
      title: "Are you sure?",
      text: "Once deleted, you will not be able to recover this user!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Ok",
      cancelButtonText: "cancel",
      reverseButtons: true,
    }).then((result) => {
      if (result.value) {
        const res = adminServices.updateListing(id, { isDeleted: true });
        if (res.status) {
          getList();
          SweetAlert.fire("Deleted!", "Your User has been deleted.", "success");
        }

        getList();
      } else {
        SweetAlert.fire("Your file is safe!");
      }
      getList();
    });
  };

  const onUpdateStatus = (row) => {
    history(`${process.env.PUBLIC_URL}/app/listing-info/${row._id} `, {
      state: row,
    });
  };

  const handleRowSelected = (state) => {
    setSelectedListing(state.selectedRows);
  };

  const deleteListing = async () => {
    SweetAlert.fire({
      title: "Are you sure?",
      text: "Once deleted, you will not be able to recover this user!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Ok",
      cancelButtonText: "cancel",
      reverseButtons: true,
    }).then((result) => {
      if (result.value) {
        const ids = selectedListing.map((d) => d._id);
        const res = adminServices.deleteMultipleListing({ ids: ids });
        if (res.status) {
          getList();
          SweetAlert.fire("Deleted!", "Your User has been deleted.", "success");
        }

        getList();
      } else {
        SweetAlert.fire("Your file is safe!");
      }
      getList();
    });

    // const ids = selectedListing.map((d) => d._id);
    // const res = await adminServices.deleteMultipleListing({ids : ids});
    // if(res){
    //   getList();

    // }
  };

  const uploadListings = async (event) => {
    const file = event.target.files[0];
    var formData = new FormData();
    formData.append("listings", file);
    const res = await adminServices.uploadListings(formData);
    console.log("RES", res);

    if (!res.status) {
      alert(res.data);
    } else {
      alert("Successfully created the listings");
    }
  };

  const tableData = {
    name: {
      name: "User Name",
      selector: (row) => row?.user_id?.name,
      sortable: true,
    },
    category: {
      name: "Category",
      selector: (row) => row.equipment_id?.title,
      sortable: true,
    },
    equipment_name: {
      name: "Name",
      selector: (row) => row.equipment_name,
      sortable: true,
    },
    brand: {
      name: "Brand",
      selector: (row) => row.brand,
      sortable: true,
      center: true,
    },
    model: {
      name: "Model",
      selector: (row) => row.model,
      sortable: true,
      center: true,
    },
    mrg_year: {
      name: "MfgYear",
      selector: (row) => row.mrg_year,
      sortable: true,
      center: true,
    },
    capacity: {
      name: "Capacity",
      selector: (row) => row.capacity,
    },
    created: {
      name: "createAt",
      selector: (row) => {
        return moment(row?.createdAt).format("DD-MM-YYYY");
      },
      sortable: true,
      center: true,
    },
    status: {
      name: "Status",
      selector: (row) => row.status,
      sortable: true,
      center: true,
    },
    mobile: {
      name: "User",
      selector: (row) => {
        let data = row.user_id?.mobile;
        if (row.user_id?.name) {
          data += "-" + row.user_id?.name;
        }
        return data;
      },
      sortable: true,
    },
    address: {
      name: "Address",
      selector: (row) => {
        if (!row.address) {
          return "";
        }
        if (row.address.type) {
          return <div>{row.address.type}</div>;
        } else {
          return <div>{row.address.name - row.address.pin_code}</div>;
        }
      },
      center: true,
    },
    action: {
      name: "Action",
      selector: (row) => {
        return (
          <div>
            <span>
              <i
                className="fa fa-eye"
                style={{
                  cursor: "pointer",
                  width: 35,
                  fontSize: 16,
                  padding: 11,
                  color: "rgb(40, 167, 69)",
                }}
                onClick={() => onUpdateStatus(row)}
              ></i>
            </span>

            <span>
              <i
                className="fa fa-pencil"
                style={{
                  cursor: "pointer",
                  width: 35,
                  fontSize: 16,
                  padding: 11,
                  color: "rgb(40, 167, 69)",
                }}
                onClick={() => onUpdateUser(row)}
              ></i>
            </span>
            <span>
              <i
                className="fa fa-trash"
                style={{
                  width: 35,
                  fontSize: 16,
                  padding: 11,
                  color: "#e4566e",
                }}
                onClick={() => onRemoveListing(row._id)}
              ></i>
            </span>
          </div>
        );
      },
      sortable: true,
      center: true,
    },
  };

  var conf = localStorage.getItem("listingConf");

  if (conf) {
    conf = JSON.parse(conf);
  } else {
    conf = [
      { value: "name", title: "Name", selected: true },
      { value: "mobile", title: "Mobile", selected: true },
      { value: "category", title: "Category", selected: true },
      { value: "equipment_name", title: "Equipment Name", selected: false },
      { value: "brand", title: "Brand", selected: true },
      { value: "model", title: "Model", selected: true },
      { value: "capacity", title: "Capcacity", selected: true },
      { value: "mrg_year", title: "Mfg Year", selected: false },
      { value: "status", title: "Status", selected: false },
      { value: "address", title: "Address", selected: false },
      { value: "created", title: "Created At", selected: false },
      { value: "action", title: "Action", selected: true },
    ];
  }

  const [filter, setFilter] = useState(conf);

  const content =
    filter && filter?.filter((d) => d.selected)?.map((d1) => d1.value);

  const tableColumns = [];

  Object.keys(tableData).map((d) => {
    if (content.includes(d)) {
      tableColumns.push(tableData[d]);
    }
  });

  const openPopHandler = () => {
    setVaryingContentone(!VaryingContentone);
    setFilter(filter);
  };

  //   const handleCheckboxChange = (value, checked) => {
  //     if (checked) {
  //         setCheckboxes([...checkboxes, value]);
  //     } else {
  //         setCheckboxes(checkboxes.filter((item) => item !== value));
  //     }
  // };
  const showhideHandler = (values, checked) => {
    const index = filter.findIndex((val) => val.value == values.value);
    const j = JSON.parse(JSON.stringify(filter));

    j[index] = { ...values, selected: checked };
    setFilter(j);
  };

  const handlePageChange = (page) => {
    setPage(page);
  };

  const handlePerRowsChange = async (newPerPage, page) => {
    setPageItems(newPerPage);
  };

  return (
    <Fragment>
      <Breadcrumb
        parent="Table"
        title="Listing"
        buttonOptions={() => (
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "flex-end",
            }}
          >
            <div style={{ marginRight: "10px" }}>
              <Input onChange={(event) => uploadListings(event)} type="file" />
            </div>
            {/* <Button onClick={()=>openPopHandler()}>Filter</Button> */}

            <CiExport
              onClick={() => handler()}
              style={{
                color: "#d63384",
                marginRight: "10px",
                fontWeight: "bold",
              }}
              size={28}
            />

            <Select
              // value={status}
              onChange={(event) => {
                setStatus(event.value);
              }}
              styles={{ margin: "10px", minWidth: "250px" }}
              options={options}
            />

            <Button
              style={{ marginLeft: "10px" }}
              onClick={() => openPopHandler()}
            >
              Filter
            </Button>
            {/* <Select
            // value={status}
            isMulti={true}
            onChange={values => {
            console.log(values)
              setTabLayout(values);
            }}
            styles={{ margin: '10px', minWidth: '250px' }}
            options={filter} /> */}

            {selectedListing?.length !== 0 && (
              <Button
                color="primary"
                style={{ marginLeft: "10px" }}
                onClick={() => deleteListing()}
              >
                Delete
              </Button>
            )}
          </div>
        )}
        renderOptionss={() => (
          <Select
            value={status}
            onChange={(event) => {
              setStatus(event.value);
            }}
            style={{ margin: "10px" }}
            options={options}
          />
        )}
      />

      <Container fluid={true}>
        <Row>
          <Col sm="12">
            <Card>
              <Modal
                isOpen={VaryingContentone}
                style={{ width: "30%" }}
                onClosed={() =>
                  localStorage.setItem("listingConf", JSON.stringify(filter))
                }
                toggle={() => setVaryingContentone(!VaryingContentone)}
              >
                <ModalHeader
                  toggle={() => setVaryingContentone(!VaryingContentone)}
                >
                  {"Display"}
                </ModalHeader>
                <ModalBody>
                  <Form>
                    {filter.map((i) => {
                      return (
                        <div style={{ margin: "20px" }}>
                          <input
                            type="checkbox"
                            defaultChecked={i.selected}
                            value={i.selected}
                            name={i}
                            onChange={(e) =>
                              showhideHandler(i, e.target.checked)
                            }
                          />
                          <span style={{ marginLeft: "30px" }}>{i.title}</span>
                        </div>
                      );
                    })}
                  </Form>
                </ModalBody>
              </Modal>

              <CardBody>
                <DataTable
                  data={data}
                  columns={tableColumns}
                  center={true}
                  selectableRows
                  progressPending={loader}
                  onSelectedRowsChange={handleRowSelected}
                  pagination
                  paginationServer
                  paginationTotalRows={totalItems}
                  paginationPerPage={pageItems}
                  onChangePage={handlePageChange}
                  onChangeRowsPerPage={handlePerRowsChange}
                />
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </Fragment>
  );
};
export default ListingData;
