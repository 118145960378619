import React, { useState, useEffect } from "react";
import adminServices from "../../api/adminService";
import {
  Row,
  Col,
  Card,
  CardBody,
  CardHeader,
  Button,
  Modal,
  ModalBody,
  ModalFooter,
  Form,
  ModalHeader,
  Label,
  Input,
  FormGroup,
  FormFeedback,
} from "reactstrap";
import SweetAlert from "sweetalert2";

import { Container } from "react-bootstrap";
import DataTable from "react-data-table-component";

import Breadcrumb from "../../layout/breadcrumb";
import { Fragment } from "react";
import moment from "moment/moment";
import Loading from "./Loading";
import { useNavigate } from "react-router";

const InCompleteProfile = () => {
  const history = useNavigate();

  const [profileData, setProfileData] = useState([]);
  const [loader, setLoader] = useState(false);
  const [VaryingContentone, setVaryingContentone] = useState(false);

  const tableData = {
    name: {
      name: "Name",
      selector: (row) => row?.name,
      sortable: true,
    },
    email: {
      name: "Email",
      selector: (row) => row?.email,
      sortable: true,
    },
    mobile: {
      name: "Mobile",
      selector: (row) => row?.mobile,
      sortable: true,
    },
    status: {
      name: "Status",
      selector: (row) => row.status,
      sortable: true,
    },
    type: {
      name: "Type",
      selector: (row) => row.type,
      sortable: true,
    },
    createdat: {
      name: "CreatedAt",
      selector: (row) => {
        return moment(row?.createdAt).format("DD-MM-YYYY");
      },
      sortable: true,
      center: true,
    },
    icons: {
      name: "Action",
      selector: (row) => {
        return (
          <div>
            <span>
              <i
                className="fa fa-pencil"
                style={{
                  cursor: "pointer",
                  width: 35,
                  fontSize: 16,
                  padding: 11,
                  color: "rgb(40, 167, 69)",
                }}
                onClick={() => onUpdateIncompleteProfile(row)}
              ></i>
            </span>
            <span>
              <i
                className="fa fa-trash"
                style={{
                  width: 35,
                  fontSize: 16,
                  padding: 11,
                  color: "#e4566e",
                }}
                onClick={() => onRemoveUser(row.mobile)}
              ></i>
            </span>
          </div>
        );
      },
      sortable: true,
      center: true,
    },
  };

  const onRemoveUser = (id) => {
    SweetAlert.fire({
      title: "Are you sure?",
      text: "Once deleted, you will not be able to recover this user!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Ok",
      cancelButtonText: "cancel",
      reverseButtons: true,
    }).then(async (result) => {
      if (result.value) {
        const res = await adminServices.deleteUser(id);
        if (res.status) {
          SweetAlert.fire("Deleted!", "Your User has been deleted.", "success");
          getinCompleteProfile();
        } else {
          SweetAlert.fire("Please try again");
        }
      } else {
        SweetAlert.fire("Please try again");
      }
    });
  };

  var conf = localStorage.getItem("incomplteprofileconf");
  console.log("CONF", conf);

  if (conf) {
    conf = JSON.parse(conf);
  } else {
    conf = [
      { value: "name", title: "Name", selected: true },
      { value: "email", title: "Email", selected: true },
      { value: "mobile", title: "Mobile", selected: true },
      { value: "status", title: "Status", selected: true },
      { value: "type", title: "Type", selected: false },
      { value: "createdat", title: "Created At", selected: false },
      { value: "icons", title: "icons", selected: true },
    ];
  }

  const [filter, setFilter] = useState(conf);

  const content =
    filter && filter?.filter((d) => d.selected)?.map((d1) => d1.value);

  const tableColumns = [];

  Object.keys(tableData).map((d) => {
    if (content.includes(d)) {
      tableColumns.push(tableData[d]);
    }
  });

  const onUpdateIncompleteProfile = (row) => {
    history(`${process.env.PUBLIC_URL}/app/user/${row._id}`, {
      state: { flow: true, state: row },
    });
  };

  async function getinCompleteProfile() {
    setLoader(true);

    const res = await adminServices.inCompleteProfile();
    if (res.status) {
      setLoader(false);
      // setHideDirector("Admin")
      setProfileData(res.data);
    } else {
      setProfileData([]);
    }
  }

  useEffect(() => {
    getinCompleteProfile();
  }, []);

  const openPopHandler = () => {
    setVaryingContentone(!VaryingContentone);
    setFilter(filter);
  };
  const showhideHandler = (values, checked) => {
    const index = filter.findIndex((val) => val.value == values.value);
    const j = JSON.parse(JSON.stringify(filter));

    j[index] = { ...values, selected: checked };
    setFilter(j);
  };
  return (
    <Fragment>
      <Breadcrumb
        parent="Table"
        title="Incomplete Profiles"
        renderOptions={() => {
          return (
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "flex-end",
              }}
            >
              <Button
                style={{ marginLeft: "10px" }}
                onClick={() => openPopHandler()}
              >
                Filter
              </Button>
            </div>
          );
        }}
      />

      {loader ? (
        <Loading />
      ) : (
        <Container fluid={true}>
          <Row>
            <Col sm="12">
              <Card>
                <Modal
                  isOpen={VaryingContentone}
                  style={{ width: "30%" }}
                  onClosed={() =>
                    localStorage.setItem(
                      "incomplteprofileconf",
                      JSON.stringify(filter)
                    )
                  }
                  toggle={() => setVaryingContentone(!VaryingContentone)}
                >
                  <ModalHeader
                    toggle={() => setVaryingContentone(!VaryingContentone)}
                  >
                    {"Display"}
                  </ModalHeader>
                  <ModalBody>
                    <Form>
                      {filter.map((i) => {
                        return (
                          <div style={{ margin: "20px" }}>
                            <input
                              type="checkbox"
                              defaultChecked={i.selected}
                              value={i.selected}
                              name={i}
                              onChange={(e) =>
                                showhideHandler(i, e.target.checked)
                              }
                            />
                            <span style={{ marginLeft: "30px" }}>
                              {i.title}
                            </span>
                          </div>
                        );
                      })}
                    </Form>
                  </ModalBody>
                </Modal>

                <CardBody>
                  <DataTable
                    data={profileData}
                    columns={tableColumns}
                    center={true}
                    pagination
                    // selectableRows
                    // onSelectedRowsChange={handleRowSelected}
                    // clearSelectedRows={toggleCleared}
                  />
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      )}
    </Fragment>
  );
};
export default InCompleteProfile;
