import React, { Fragment, useState, useEffect } from "react";
import Breadcrumb from "../../layout/breadcrumb";

import {
  Container,
  Row,
  Col,
  Card,
  CardBody,
  Form,
  FormGroup,
  Label,
  Input,
  Button,
  FormFeedback,
  CardFooter,
} from "reactstrap";
import { useForm } from "react-hook-form";
import { useDispatch } from "react-redux";
import adminServices from "../../api/adminService";
import { toast } from "react-toastify";
import { useLocation } from "react-router";
import { useNavigate } from "react-router-dom";
import Select from "react-select";

const SaveSupplier = (props) => {
  const location = useLocation();
  const data = location?.state;
  const history = useNavigate();

  const [formValues, setFormValues] = useState({
    name: data?.name ? data.name : "",
    email: data?.email ? data.email : "",
    password: "",
    mobile: data?.mobile ? data.mobile : "",
    type: data?.type ? data.type : "User",
    organization_name: data?.organization_name ? data.organization_name : "",
    role: data?.role ? data.role : "",
  });

  console.log("formValues", formValues);
  const [formErrors, setFormErrors] = useState({
    name: "",
    email: "",
    password: "",
    mobile: "",
    type: "",
    organization_name: "",
    role: "",
  });
  //   const [role, setRole] = useState("")
  const [roleList, setRoleList] = useState([]);

  async function getRole() {
    const res = await adminServices.getRole();
    if (res.status) {
      setRoleList(res.data);
    } else {
      setRoleList("");
    }
  }
  useEffect(() => {
    getRole();
  }, []);

  const dropdownData =
    roleList &&
    roleList.map((item) => {
      return {
        label: item.title,
        value: item.title,
      };
    });

  const selectDropdown = (event) => {
    // setFormValues({ ...formValues,role: event.value });
    setFormValues({ ...formValues, role: event.target.value });
  };
  const onSubmit = async () => {
    let errors = {};
    if (!formValues.name) {
      errors.name = "Please enter the Name";
    }
    if (!formValues.email) {
      errors.email = "Please enter the Email";
    }

    if (!data && !formValues.password) {
      errors.password = "Please enter the Password";
    }
    if (!formValues.mobile) {
      errors.mobile = "Please enter the Mobile Number";
    }
    if (!formValues.organization_name) {
      errors.organization_name = "Please enter the Organisation Name";
    }
    // if (!formValues.role) {
    //     errors.role = "Please enter the Role"
    // }
    if (Object.keys(errors).length > 0) {
      setFormErrors(errors);

      return;
    }

    if (data) {
      const res = await adminServices.updateUser(data._id, {
        name: formValues.name,
        email: formValues.email,
        mobile: formValues.phone,
        type: formValues.type,
        organization_name: formValues.organization_name,
        role: formValues.role,
      });
      if (res.status) {
        toast(res.message);
        //    history(`${process.env.PUBLIC_URL}/app/users/Dubai`);
        history(`${process.env.PUBLIC_URL}/app/Suppliers/Dubai`);
        // history(`${process.env.PUBLIC_URL}/app/users/Dubai`, {state: res.data.type});
      } else {
        toast.error(res.message);
      }
    } else {
      const res = await adminServices.userCreation(formValues);
      console.log(res);
      if (res.status) {
        toast(res.message);
        //    history( `${process.env.PUBLIC_URL}/app/admin/Dubai`);
        history(`${process.env.PUBLIC_URL}/app/Suppliers/Dubai`);
        //    history(`${process.env.PUBLIC_URL}/app/users/Dubai`, {
        //      state: res.data.type,
        //    });
      } else {
        toast.error(res.message);
      }
    }
  };

  const handleOnChange = (event) => {
    setFormValues({ ...formValues, [event.target.name]: event.target.value });
    setFormErrors({ ...formErrors, [event.target.name]: "" });
  };

  return (
    <Fragment>
      <Breadcrumb title={data ? "Edit Supplier" : "Create "} />
      {/* <Container fluid={true} className="projectlist" > */}
      <Row>
        <Col sm="12">
          <Card>
            <CardBody>
              <Form className="theme-form">
                <Row>
                  <Col sm="4">
                    <FormGroup className="m-t-1">
                      <Label>{"Name"}</Label>
                      <Input
                        className="form-control"
                        type="text"
                        name="name"
                        value={formValues.name}
                        defaultValue=""
                        valid={formErrors.name}
                        invalid={formErrors.name}
                        onChange={(e) => handleOnChange(e)}
                        required
                        placeholder={"Name"}
                      />
                      <FormFeedback>{formErrors.name}</FormFeedback>
                    </FormGroup>
                  </Col>
                  <Col sm="4">
                    <FormGroup className="m-t-1">
                      <Label>{"Email"}</Label>
                      <Input
                        className="form-control"
                        type="text"
                        name="email"
                        placeholder={"Email"}
                        onChange={(e) => handleOnChange(e)}
                        required="true"
                        value={formValues.email}
                        valid={formErrors.email}
                        invalid={formErrors.email}
                      />
                      <FormFeedback>{formErrors.email}</FormFeedback>
                    </FormGroup>
                  </Col>
                  {!data ? (
                    <Col sm="4" className="m-t-1">
                      <FormGroup>
                        <Label>{"Password"}</Label>
                        <Input
                          className="form-control"
                          type="text"
                          name="password"
                          required="true"
                          onChange={(e) => handleOnChange(e)}
                          placeholder={"Password"}
                          valid={formErrors.password}
                          invalid={formErrors.password}
                        />
                        <FormFeedback>{formErrors.password}</FormFeedback>
                      </FormGroup>
                    </Col>
                  ) : null}{" "}
                  {!data ? (
                    <Col sm="4">
                      <FormGroup className="m-t-1">
                        <Label>{"Mobile Number"}</Label>
                        <Input
                          className="form-control"
                          type="text"
                          name="mobile"
                          required="true"
                          placeholder={"Mobile Number"}
                          valid={formErrors.mobile}
                          value={formValues.mobile}
                          invalid={formErrors.mobile}
                          onChange={(e) => handleOnChange(e)}
                        />
                        <FormFeedback>{formErrors.mobile}</FormFeedback>
                      </FormGroup>
                    </Col>
                  ) : (
                    ""
                  )}
                  <Col sm="4">
                    <FormGroup className="m-t-1">
                      <Label>{"Type"}</Label>
                      <Input
                        type="select"
                        name="type"
                        placeholder={"Type"}
                        onChange={(e) => handleOnChange(e)}
                        valid={formErrors.type}
                        invalid={formErrors.type}
                        value={formValues.type}
                        className="form-control digits"
                      >
                        <option value={"User"}>{"User"}</option>

                        <option value={"Admin"}>{"Admin"}</option>
                      </Input>
                      <FormFeedback>{formErrors.type}</FormFeedback>
                    </FormGroup>
                  </Col>
                  <Col sm="4">
                    <FormGroup className="m-t-1">
                      <Label>{"Organisation Name"}</Label>
                      <Input
                        className="form-control"
                        type="text"
                        name="organization_name"
                        placeholder={"Org-Name"}
                        onChange={(e) => handleOnChange(e)}
                        required="true"
                        value={formValues.organization_name}
                        valid={formErrors.organization_name}
                        invalid={formErrors.organization_name}
                      />
                      <FormFeedback>
                        {formErrors.organization_name}
                      </FormFeedback>
                    </FormGroup>
                  </Col>
                  <Col sm="4">
                    <FormGroup className="m-t-1">
                      <Label>{"Role"}</Label>

                      <Input
                        type="select"
                        name="role"
                        placeholder={"Role"}
                        onChange={(e) => handleOnChange(e)}
                        valid={formErrors.role}
                        invalid={formErrors.role}
                        value={formValues.role}
                        className="form-control digits"
                      >
                        <option value={""}>{"Please Select"}</option>
                        {roleList &&
                          roleList?.map((d) => {
                            return (
                              <option value={d.value ? d.value : d.title}>
                                {d.title}
                              </option>
                            );
                          })}
                        {/* <option value={"Jcb"}>{"Jcb"}</option>
                                                <option value={""}>{""}</option> */}
                      </Input>

                      <FormFeedback>{formErrors.role}</FormFeedback>
                    </FormGroup>
                  </Col>
                </Row>

                {/* <Row>
                    <Col>
                      <FormGroup>
                        <Button color="primary" className="me-3 m-t-1" onClick={() => onSubmit()}>{Save}</Button>

                      </FormGroup>
                    </Col>
                  </Row> */}
              </Form>
            </CardBody>
            <CardFooter>
              <Button
                color="primary"
                className="me-3 "
                onClick={() => onSubmit()}
              >
                {"Save"}
              </Button>
            </CardFooter>
          </Card>
        </Col>
      </Row>
      {/* </Container> */}
    </Fragment>
  );
};

export default SaveSupplier;
