
import React, { Fragment } from "react";
import { useLocation } from "react-router";
import { Container, Row, Col, Card, CardBody, CardHeader } from "reactstrap";
import Breadcrumb from "../../layout/breadcrumb";
import moment from "moment/moment";


const EnquiriesData = () => {

    const location = useLocation();
    const enquire = location.state;

    let bookings = enquire.booking_id;
    let listing = enquire.listing_id;
    let supplier = enquire.supplier_id;


    return (
        <Fragment>
            <Breadcrumb parent="Ecommerce" title="Product Page" />
            <Container fluid={true}>
                <div className="product-page-main p-0">

                    {
                        enquire ? <Row>
                            <Col style={{ marginLeft: 30 }}>
                                <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", width: "40%" }}>
                                    <h6 style={{ fontSize: 20 }}>
                                        Status
                                    </h6>
                                    <p style={{ fontSize: 20 }}>{enquire.status}</p>

                                </div>
                                <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", width: "40%" }}>
                                    <h6 style={{ fontSize: 20 }}>
                                        Validity
                                    </h6>
                                    <p style={{
                                        fontSize: 18
                                    }}>{moment(enquire.validity).format("DD-MM-YYYY")}</p>

                                </div>
                            </Col>
                        </Row> : null
                    }

                    <Row>
                        <Col xl="4 xl-55 box-col-6">
                            {
                                bookings ? <Card style={{ minHeight: 450 }}>
                                    <CardHeader>
                                        <h4>Booking</h4>
                                    </CardHeader>
                                    <CardBody>

                                        <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", }}>
                                            <p style={{ fontSize: 15, color: "#757575" }}>
                                                Quantity
                                            </p>
                                            <h6 style={{ fontSize: 15 }}>{bookings.quantity}</h6>

                                        </div>
                                        <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between" }}>
                                            <p style={{ fontSize: 15, color: "#757575" }}>
                                                Shift
                                            </p>
                                            <h6 style={{ fontSize: 15 }}>{bookings.shift}</h6>

                                        </div>
                                        <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between" }}>
                                            <p style={{ fontSize: 15, color: "#757575" }}>
                                                Specification
                                            </p>
                                            <h6 style={{ fontSize: 15 }}>{bookings.specification}</h6>

                                        </div>
                                        <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between" }}>
                                            <p style={{ fontSize: 15, color: "#757575" }}>
                                                Status
                                            </p>
                                            <h6 style={{ fontSize: 15 }}>{bookings.status}</h6>

                                        </div>
                                        <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between" }}>
                                            <p style={{ fontSize: 15, color: "#757575" }}>
                                                Rental Period
                                            </p>
                                            <h6>{`${moment(bookings.rental_period[0]).format("DD-MM-YYYY")} 
                                             to ${moment(bookings.rental_period[1]).format("DD-MM-YYYY")}`}</h6>

                                        </div>
                                    </CardBody>


                                </Card> : null
                            }

                        </Col>

                        <Col xl="3 xl-45 box-col-6">

                            {
                                supplier ? <Card style={{ minHeight: 450 }}>
                                    <CardHeader>
                                        <h4>Supplier</h4>
                                    </CardHeader>
                                    <CardBody>
                                        <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", }}>
                                            <p style={{ fontSize: 15, color: "#757575" }}>
                                                Email
                                            </p>
                                            <h6 style={{ fontSize: 15 }}>{supplier.email}</h6>

                                        </div>
                                        <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between" }}>
                                            <p style={{ fontSize: 15, color: "#757575" }}>
                                                Mobile
                                            </p>
                                            <h6 style={{ fontSize: 15 }}>{supplier.mobile}</h6>

                                        </div>
                                        <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", }}>
                                            <p style={{ fontSize: 15, color: "#757575" }}>
                                                Name
                                            </p>
                                            <h6 style={{ fontSize: 15 }}>{supplier.name}</h6>

                                        </div>

                                    </CardBody>


                                </Card> : null
                            }
                            {
                                listing ? <Card style={{ minHeight: 450 }}>
                                    <CardHeader>
                                        <h4>Listing</h4>
                                    </CardHeader>
                                    <CardBody>
                                        <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", }}>
                                            <p style={{ fontSize: 15, color: "#757575" }}>
                                                Brand
                                            </p>
                                            <h6 style={{ fontSize: 15 }}>{listing.brand}</h6>

                                        </div>
                                        <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between" }}>
                                            <p style={{ fontSize: 15, color: "#757575" }}>
                                                Model
                                            </p>
                                            <h6 style={{ fontSize: 15 }}>{listing.model}</h6>

                                        </div>
                                        <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", }}>
                                            <p style={{ fontSize: 15, color: "#757575" }}>
                                                Manufacturing Year
                                            </p>
                                            <h6 style={{ fontSize: 15 }}>{listing.mrg_year}</h6>

                                        </div>
                                        <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between" }}>
                                            <p style={{ fontSize: 15, color: "#757575" }}>
                                                Stage
                                            </p>
                                            <h6 style={{ fontSize: 15 }}>{listing.stage}</h6>

                                        </div>
                                        <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", }}>
                                            <p style={{ fontSize: 15, color: "#757575" }}>
                                                Status
                                            </p>
                                            <h6 style={{ fontSize: 15 }}>{listing.status}</h6>

                                        </div>
                                    </CardBody>


                                </Card> : null
                            }


                        </Col>
                    </Row>
                </div>

            </Container>
        </Fragment>


    )
}
export default EnquiriesData;