import React, { Fragment, useState } from "react";
import { useEffect } from "react";
import adminServices from "../../api/adminService";
import { Row, Col, Card, CardBody, CardHeader, Button ,Modal, ModalBody, ModalFooter, Form,ModalHeader} from "reactstrap";
import { Container } from "react-bootstrap";
import DataTable from "react-data-table-component";
import SweetAlert from 'sweetalert2'
import { useNavigate } from "react-router";
import moment from "moment/moment";
import { classes } from '../../data/layouts'
import Breadcrumb from "../../layout/breadcrumb";
import Loading from "./Loading";

const Bookings = () => {

    const [data, setData] = useState([]);
    const [loader ,setLoader] = useState(false)
    const [VaryingContentone, setVaryingContentone] = useState(false);


    const history = useNavigate();
  
    var object = {
         status: "Pending"
    };
    const objString = new URLSearchParams(object).toString();
    
    useEffect(() => {
        setLoader(true)
        async function getBookings() {

            const res = await adminServices.getBookings(objString);
            if (res.status) {
                setLoader(false)
                setData(res.data)
            }
            else {
                setData("")
            }

        }
        getBookings()

    }, [0])




    const tableData = {
      equipment:  {
            name: 'Equipment',
            selector: row => row.equipment_id.title,
            sortable: true,

        },
     qty:   {
            name: 'Quantity',
            selector: row => row.quantity,
            sortable: true,
            center: true,
        },
      shift:  {
            name: 'Shift',
            selector: row => row.shift,
            sortable: true,
            center: true,
        },
       specifications: {
            name: 'Specifications',
            selector: row => row.specifications,
            sortable: true,
            center: true,
        },
        // {
        //     name: 'Job Site',
        //     selector: row => row.jobsite_id.name,
        //     sortable: true,
        //     center: true,
        // },
       status: {
            name: 'Status',
            selector: row => row.status,
            sortable: true,
            center: true,
        },
        from:{
            name: 'From',
            selector: row => {
                return moment(row.rental_period[0]).format("DD-MM-YYYY")
            },
            sortable: true,
            center: true,
        },
       to: {
            name: 'To',
            selector: row => {
                return moment(row.rental_period[1]).format("DD-MM-YYYY")

            },
            sortable: true,
            center: true,
        },
      bookeddate:  {
            name: 'Booked At',
            selector: row => {
                return moment().diff(moment(row.createdAt), "days") + " days ago"
            },
            sortable: true,
            center: true,
        },
      view:  {
            name: 'View',
            selector: row => {
                return <div>
                    <Button color="primary"
                        onClick={() => onViewNavigate(row)}>View</Button>
                </div>
            },
            sortable: true,
            center: true,
        },

}

var conf = localStorage.getItem("bookingconf");
console.log("CONF", conf);

if (conf) {
  conf = JSON.parse(conf);
} else {
  conf = [
    { value: 'equipment', title: "equipment", selected: true },
    { value: 'qty', title: "Quantity", selected: true },
    { value: 'shift', title: "Shift", selected: true },
    { value: 'specifications', title: "Specifications", selected: false },
    { value: 'status', title: "Status", selected: false },
    { value: 'to', title: "To date", selected: false },
    { value: 'from', title: "From date", selected: false },
    { value: 'bookeddate', title: "Booked Date", selected: false },
    { value: 'view', title: "View", selected: true },

  ]
}

const [filter, setFilter] = useState(conf);

const content = filter && filter?.filter((d) => d.selected)?.map((d1) => d1.value);

const tableColumns = [];

Object.keys(tableData).map((d) => {

  if (content.includes(d)) {

    tableColumns.push(tableData[d]);
  }
})

    const onViewNavigate = (row) => {
        history(`${process.env.PUBLIC_URL}/app/bookingDetails/${row._id}`, { state: row });
    }


    const openPopHandler = () => {
        setVaryingContentone(!VaryingContentone)
        setFilter(filter)
      }
      const showhideHandler = (values, checked) => {
    
        const index = filter.findIndex(val => val.value == values.value);
        const j = JSON.parse(JSON.stringify(filter));
    
        j[index] = { ...values, selected: checked };
        setFilter(j);
      }

    return (
        <Fragment>
            <Breadcrumb parent="Table" title="Pending Bookings" 
                buttonOptions={() => {
                  return (
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "flex-end",
                      }}
                    >
                      <Button style={{ marginLeft: "10px" }} onClick={() => openPopHandler()}>Filter</Button>
                      
                    </div>
                  );
                }}/>
           

           { loader ? <Loading /> : <Container fluid={true}>
                <Row>
                    <Col sm="12">
                        <Card>

            <Modal isOpen={VaryingContentone} style={{ width: "30%" }}
                onClosed={() => localStorage.setItem("bookingconf", JSON.stringify(filter))}
                toggle={() => setVaryingContentone(!VaryingContentone)}
              >
                <ModalHeader
                toggle={() => setVaryingContentone(!VaryingContentone)}
                >
                  {"Display"}
                </ModalHeader>
                <ModalBody>
                  <Form>

                    {filter.map(i => {
                      return (<div style={{ margin: "20px" }}>
                        <input type="checkbox" defaultChecked={i.selected} value={i.selected} name={i} onChange={(e) => showhideHandler(i, e.target.checked)} />
                        <span style={{ marginLeft: "30px" }}>{i.title}</span>
                      </div>)
                    })}


                  </Form>
                </ModalBody>
              </Modal>


                            <CardBody>
                                <DataTable
                                    data={data}
                                    columns={tableColumns}
                                    center={true}

                                />
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </Container>}
        </Fragment>
    )

}
export default Bookings;