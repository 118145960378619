import React, { Fragment, useState } from "react";
import { useEffect } from "react";
import adminServices from "../../api/adminService";
import {
  Row,
  Col,
  Card,
  Button,
  Modal,
  ModalBody,
  ModalFooter,
  Form,
  ModalHeader,
  Label,
  Input,
  FormGroup,
  FormFeedback,
  CardTitle,
} from "reactstrap";
import { Container } from "react-bootstrap";
import SweetAlert from "sweetalert2";
import { useNavigate } from "react-router";
import Breadcrumb from "../../layout/breadcrumb";
import { classes } from "../../data/layouts";
import Select from "react-select";
import { useLocation } from "react-router";
import { toast } from "react-toastify";
import Loading from "./Loading";

const RulesList = (props) => {
  const [data, setData] = useState("");
  const [VaryingContentone, setVaryingContentone] = useState(false);
  const [VaryingContent, setVaryingContent] = useState(false);
  const [rule, setRule] = useState("");
  const [value, setValue] = useState("");
  const [defaultValue, setDefaultValue] = useState("");
  const [num, setEnum] = useState("");
  const [error, setError] = useState("");
  const [selectedRule, SetSelectedRule] = useState({});
  const [load, setLoad] = useState(true);

  async function getRules() {
    setLoad(true);

    const res = await adminServices.getRules();
    if (res.status) {
      setLoad(false);
      setData(res.data);
    } else {
      setData("");
    }
  }

  useEffect(() => {
    getRules();
  }, []);

  const displayModel = (r) => {
    if (r) {
      SetSelectedRule(r);
      setRule(r.rule);
      setEnum(r.enum.toString());
      setValue(r.value);
      setDefaultValue(r.defaultValue);
      setVaryingContentone(!VaryingContentone);
    } else {
      setVaryingContentone(!VaryingContentone);
      setRule("");
      setEnum("");
      setValue("");
      setDefaultValue("");
      SetSelectedRule("");
    }
  };
  let selectEnum = selectedRule?.enum;

  const options = selectEnum?.map((element) => ({
    value: element,
    label: element,
  }));

  // const options = arrayOfStrings && arrayOfStrings?.map((element) => ({
  //   value: element,
  //   label: element.toString(),
  // }));

  const selectDropdown = (event) => {
    setEnum(event.value);
  };

  const selectValue = (event) => {
    setValue(event.value);
  };

  // let option = ""
  //  option = String(num).split("")
  //  console.log("option",option)

  const onSave = async () => {
    //  if (!rule) {
    //    setError("Please enter the Rule");
    //    return;
    //  }

    console.log("SPLIT", num.split(","));
    if (selectedRule) {
      const res = await adminServices.updateRule(selectedRule._id, {
        rule: rule,
        value: value,
        defaultValue: defaultValue,
        enum: num ? num.split(",") : [],
        //  enum:JSON.stringify(num)
        //  enum:option
      });
      if (res.status) {
        toast(res.message);
        getRules();
        setRule("");
        setValue("");
        setDefaultValue("");
        setEnum("");
        displayModel();
      } else {
        toast.error(res.message);
      }
    } else {
      const res = await adminServices.createRule({
        rule: rule,
        value: value,
        defaultValue: defaultValue,
        enum: num ? num.split(",") : [],
      });
      if (res.status) {
        toast(res.message);
        getRules();
        setRule("");
        setValue("");
        setDefaultValue("");
        setEnum("");
        displayModel();
      } else {
        toast.error(res.message);
      }
    }
  };

  const displayRulesModel = () => {
    setVaryingContent(!VaryingContent);
  };
  const viewDetails = (r) => {
    if (r) {
      SetSelectedRule(r);
      // setRule(rule)
      // setValue(value)
      // setDefaultValue(defaultValue)
      // setEnum(num)
      setVaryingContent(!VaryingContent);
    } else {
      setVaryingContentone(!VaryingContentone);
    }
  };

  const onRemoveRule = (id) => {
    SweetAlert.fire({
      title: "Are you sure?",
      text: "Once deleted, you will not be able to recover this rule!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Ok",
      cancelButtonText: "cancel",
      reverseButtons: true,
    }).then((result) => {
      if (result.value) {
        const res = adminServices.deleteRule(id);
        console.log(res);

        SweetAlert.fire("Deleted!", "Your Rule has been deleted.", "success");
        getRules();
      } else {
        SweetAlert.fire("Your file is safe!");
      }
      getRules();
    });
  };

  const numHandler = (e) => {
    setEnum(e.target.value);
  };
  const array = ["anu"];
  // const [selectedOption, setSelectedOption] = useState(null);
  // const handleSelect = (option) => {
  //   setSelectedOption(option);
  // };
  // const options = array.map((element) => ({
  //   value: element,
  //   label: element.toString(),
  // }));
  return (
    <Fragment>
      <Breadcrumb
        hideLinks={true}
        parent="Tables"
        title="Rules"
        renderOptions={() => {
          return (
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "flex-end",
              }}
            >
              <div>
                <Button color="primary" onClick={() => displayModel()}>
                  {"Add"}
                </Button>
              </div>
            </div>
          );
        }}
      />
      {load ? (
        <Loading />
      ) : (
        <Container fluid={true}>
          {
            <Row>
              {data &&
                data?.map((r) => (
                  <Col sm="6" xl="4" md="6" key={r.rule}>
                    <Card body outline color="secondary">
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          justifyContent: "space-between",
                        }}
                      >
                        <div>
                          <CardTitle>Rule : {r.rule}</CardTitle>
                          <CardTitle>
                            Default Value : {r.defaultValue}
                          </CardTitle>
                          <CardTitle>Enum : {r.enum.toString()}</CardTitle>
                        </div>

                        <div>
                          {/*<span>
                            <i
                              style={{
                                cursor: "pointer",
                                width: 35,
                                fontSize: 16,
                                // padding: 11,
                                color: "rgb(40, 167, 69)",
                              }}
                              onClick={() => viewDetails(r)}
                              className="fa fa-eye"
                            ></i>
                            </span>*/}

                          <div>
                            <i
                              className="fa fa-pencil"
                              style={{
                                cursor: "pointer",
                                width: 35,
                                fontSize: 16,
                                padding: 11,
                                color: "rgb(40, 167, 69)",
                              }}
                              onClick={() => displayModel(r)}
                            ></i>
                          </div>
                          <div>
                            <i
                              className="fa fa-trash"
                              style={{
                                cursor: "pointer",
                                width: 35,
                                fontSize: 16,
                                padding: 11,
                                color: "#e4566e",
                              }}
                              onClick={() => onRemoveRule(r._id)}
                            ></i>
                          </div>
                        </div>
                      </div>

                      <CardTitle>Value : {r.value}</CardTitle>
                    </Card>
                  </Col>
                ))}
            </Row>
          }
        </Container>
      )}
      <Container fluid={true}>
        <Row>
          <Col sm="12">
            <Card>
              <Modal isOpen={VaryingContentone} toggle={() => displayModel()}>
                <ModalHeader toggle={() => displayModel()}>
                  {"Rules"}
                </ModalHeader>
                <ModalBody>
                  <Form>
                    <FormGroup>
                      <Label className="col-form-label" for="recipient-name">
                        {"Rule:"}
                      </Label>
                      <Input
                        className="form-control"
                        type="text"
                        value={rule}
                        defaultValue={""}
                        onChange={(e) => {
                          setRule(e.target.value);
                          setError("");
                        }}
                        valid={error}
                        invalid={error}
                      />

                      <FormFeedback>{error}</FormFeedback>
                    </FormGroup>
                    <FormGroup>
                      <Label className="col-form-label" for="message-text">
                        {"Value:"}
                      </Label>
                      {selectedRule && selectedRule.enum?.length > 0 ? (
                        <Select
                          options={options}
                          // value={num}
                          defaultValue={num}
                          onChange={(event) => {
                            selectValue(event);
                          }}
                          // isClearable={true}
                          // isMulti
                        />
                      ) : (
                        <Input
                          type="text"
                          className="form-control"
                          id="text"
                          value={value}
                          defaultValue={""}
                          onChange={(e) => setValue(e.target.value)}
                        ></Input>
                      )}
                    </FormGroup>
                    <FormGroup>
                      <Label className="col-form-label" for="recipient-name">
                        {"Default Value:"}
                      </Label>
                      <input
                        style={{ marginTop: 10 }}
                        value={defaultValue}
                        defaultValue={""}
                        onChange={(e) => setDefaultValue(e.target.value)}
                        className="form-control"
                        type="text"
                      />
                    </FormGroup>
                    <FormGroup>
                      <Label className="col-form-label" for="recipient-name">
                        {"Enum:"}
                      </Label>

                      <input
                        style={{ marginTop: 10 }}
                        value={num}
                        defaultValue={""}
                        // onChange={(e) => setEnum(e.target.value)}
                        onChange={(e) => numHandler(e)}
                        className="form-control"
                        type="text"
                      />
                      {/* } */}
                    </FormGroup>
                  </Form>
                </ModalBody>
                <ModalFooter>
                  <Button color="secondary" onClick={() => displayModel()}>
                    {"Close"}
                  </Button>
                  <Button color="primary" onClick={() => onSave()}>
                    {"Save"}
                  </Button>
                </ModalFooter>
              </Modal>
            </Card>
          </Col>
        </Row>
      </Container>

      <Container fluid={true}>
        <Row>
          <Col sm="12">
            <Card>
              <Modal isOpen={VaryingContent} toggle={() => displayRulesModel()}>
                <ModalHeader toggle={() => displayRulesModel()}>
                  {"Rules"}
                </ModalHeader>
                <ModalBody>
                  <Form>
                    <FormGroup>
                      <Label className="col-form-label" for="recipient-name">
                        {"Rule:"}
                      </Label>
                      {selectedRule.rule}
                    </FormGroup>
                    <FormGroup>
                      <Label className="col-form-label" for="message-text">
                        {"Value:"}
                      </Label>
                      {selectedRule.value}
                    </FormGroup>
                    <FormGroup>
                      <Label className="col-form-label" for="recipient-name">
                        {"Default Value:"}
                      </Label>
                      {selectedRule.defaultValue}
                    </FormGroup>
                  </Form>
                </ModalBody>
                <ModalFooter>
                  <Button color="secondary" onClick={() => displayRulesModel()}>
                    {"Close"}
                  </Button>
                </ModalFooter>
              </Modal>
            </Card>
          </Col>
        </Row>
      </Container>
    </Fragment>
  );
};
export default RulesList;
