import React, { Fragment, useState } from "react";
import { useEffect } from "react";
import adminServices from "../../api/adminService";
import {
  Row,
  Col,
  Card,
  CardBody,
  CardHeader,
  Button,
  Table,
  ModalHeader,
  Modal,
  ModalBody,
} from "reactstrap";
import { Container } from "react-bootstrap";
import DataTable from "react-data-table-component";
import SweetAlert from "sweetalert2";
import { useNavigate } from "react-router";
import Breadcrumb from "../../layout/breadcrumb";
import { classes } from "../../data/layouts";
import moment from "moment/moment";
import Select from "react-select";

import Form from "react-bootstrap/Form";
import InputGroup from "react-bootstrap/InputGroup";
import Loading from "./Loading";

const Enquiries = () => {
  const [data, setData] = useState([]);
  const [contacts, setContacts] = useState(data);
  const [search, setSearch] = useState("");
  const [status, setStatus] = useState("Submitted");
  const [loader, setLoader] = useState(false);
  const [VaryingContentone, setVaryingContentone] = useState(false);

  const history = useNavigate();
  const defaultLayoutObj = classes.find(
    (item) => Object.values(item).pop(1) === "compact-wrapper"
  );
  const layout =
    localStorage.getItem("layout") || Object.keys(defaultLayoutObj).pop();

  const options = [
    { value: "all", label: "All" },
    { value: "Pending", label: "Pending" },
    { value: "Submitted", label: "Reserved" },
  ];

  useEffect(() => {
    async function getEnquiries() {
      let qryString = "";
      if (status !== "all") {
        qryString = new URLSearchParams({ status: status }).toString();
        console.log(qryString);
      }
      setLoader(true);
      const res = await adminServices.getEnquiries(qryString);
      if (res.status) {
        setLoader(false);
        setData(res.data);
      } else {
        setData("");
      }
    }
    getEnquiries();
  }, [status]);

  const selectDropdown = (event) => {
    setStatus(event.value);
  };

  const onEnquires = (d) => {
    history(`${process.env.PUBLIC_URL}/app/enquiriesdata/${d._id}`, {
      state: d,
    });
  };

  const tableData = {
    id: {
      name: "Id",
      selector: (row) => row?.uid,
      sortable: true,
    },
    status: {
      name: "Status",
      selector: (row) => row?.status,
      sortable: true,
      center: true,
    },
    createdate: {
      name: "Create Date",
      selector: (row) => moment(row?.CreateAt).format("DD-MM-YYYY"),
      sortable: true,
      center: true,
    },
    rentalperiodfrom: {
      name: "Rental Period from",
      selector: (row) =>
        moment(row.booking_id?.rental_period[0]).format("DD-MM-YYYY"),
      sortable: true,
      center: true,
    },
    to: {
      name: "to",
      selector: (row) =>
        moment(row.booking_id?.rental_period[1]).format("DD-MM-YYYY"),
      sortable: true,
      center: true,
    },
    qty: {
      name: "Quantity",
      selector: (row) => row?.booking_id?.quantity,
      sortable: true,
      center: true,
    },
    shift: {
      name: "Shift",
      selector: (row) => row?.booking_id?.shift,
      sortable: true,
      center: true,
    },
    email: {
      name: "S Email",
      selector: (row) => row?.supplier_id?.email,
      sortable: true,
      center: true,
    },
    mobile: {
      name: "S Mobile",
      selector: (row) => row?.supplier_id?.mobile,
      sortable: true,
      center: true,
    },
    name: {
      name: "S Name",
      selector: (row) => row?.supplier_id?.name,
      sortable: true,
      center: true,
    },
    orgname: {
      name: "S Org Name",
      selector: (row) => row?.supplier_id?.organization_name,
      sortable: true,
      center: true,
    },
  };

  var conf = localStorage.getItem("enquiresConf");
  console.log("CONF", conf);

  if (conf) {
    conf = JSON.parse(conf);
  } else {
    conf = [
      { value: "id", title: "Id", selected: true },
      { value: "status", title: "Status", selected: true },
      { value: "createdate", title: "Created Date", selected: true },
      {
        value: "rentalperiodfrom",
        title: "Rental period from",
        selected: false,
      },
      { value: "to", title: "Rental period to", selected: false },
      { value: "qty", title: "Quantity", selected: false },
      { value: "shift", title: "Shift", selected: false },
      { value: "email", title: "Email", selected: false },
      { value: "mobile", title: "Mobile", selected: false },
      { value: "name", title: "Name", selected: false },
      { value: "orgname", title: "Organization", selected: true },
    ];
  }

  const [filter, setFilter] = useState(conf);

  const content =
    filter && filter?.filter((d) => d.selected)?.map((d1) => d1.value);

  const tableColumns = [];

  Object.keys(tableData).map((d) => {
    if (content.includes(d)) {
      tableColumns.push(tableData[d]);
    }
  });

  const obj = data && data.map((s) => Object.keys(s));

  const openPopHandler = () => {
    setVaryingContentone(!VaryingContentone);
    setFilter(filter);
  };

  //   const handleCheckboxChange = (value, checked) => {
  //     if (checked) {
  //         setCheckboxes([...checkboxes, value]);
  //     } else {
  //         setCheckboxes(checkboxes.filter((item) => item !== value));
  //     }
  // };
  const showhideHandler = (values, checked) => {
    const index = filter.findIndex((val) => val.value == values.value);
    const j = JSON.parse(JSON.stringify(filter));

    j[index] = { ...values, selected: checked };
    setFilter(j);
  };

  return (
    <Fragment>
      <Breadcrumb
        parent="Table"
        title="Enquires"
        renderOptions={() => {
          return (
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "flex-end",
              }}
            >
              <div>
                <div>
                  <Form.Control
                    onChange={(e) => setSearch(e.target.value)}
                    placeholder="Search id.."
                  />
                </div>
              </div>
              <div style={{ marginLeft: "8%", width: "100%" }}>
                <Select
                  onChange={(event) => {
                    selectDropdown(event);
                  }}
                  // styles={{ width: "40%"}}
                  options={options}
                />
              </div>
              <Button
                style={{ marginLeft: "10px" }}
                onClick={() => openPopHandler()}
              >
                Filter
              </Button>
            </div>
          );
        }}
      />

      {loader ? (
        <Loading />
      ) : (
        <Container fluid={true}>
          <Row>
            <Col sm="12">
              <Card>
                <Modal
                  isOpen={VaryingContentone}
                  style={{ width: "30%" }}
                  onClosed={() =>
                    localStorage.setItem("enquiresConf", JSON.stringify(filter))
                  }
                  toggle={() => setVaryingContentone(!VaryingContentone)}
                >
                  <ModalHeader
                    toggle={() => setVaryingContentone(!VaryingContentone)}
                  >
                    {"Display"}
                  </ModalHeader>
                  <ModalBody>
                    <Form>
                      {filter.map((i) => {
                        return (
                          <div key={"eqn"+ i} style={{ margin: "20px" }}>
                            <input
                              type="checkbox"
                              defaultChecked={i.selected}
                              value={i.selected}
                              name={i}
                              onChange={(e) =>
                                showhideHandler(i, e.target.checked)
                              }
                            />
                            <span style={{ marginLeft: "30px" }}>
                              {i.title}
                            </span>
                          </div>
                        );
                      })}
                    </Form>
                  </ModalBody>
                </Modal>

                <CardBody>
                  {/* onChange for search */}
                  {/* <Col sm="12">
<Row className="card-block">
<Col sm="12" lg="12" xl="12"><Table striped bordered hover>
<thead>
<tr> <th scope="col">{"Id"}</th>
<th scope="col" className="table_data">{"Status"}</th>
<th scope="col">{"Rental Period from"}</th>
<th scope="col">{"to"}</th>
<th scope="col">{"Quantity"}</th>
<th scope="col">{"Shift"}</th></tr>
</thead>
<tbody>
{data && data
.filter((d) => {
return search.toLowerCase() === '' ? d
: d.uid.toLowerCase().includes(search);
})
.map((d, index) => (
<tr key={index}>
<td> {d.uid} </td>
<td>
{d.status}
</td>
<td>
{d.booking_id && moment(d.booking_id.rental_period[0]).format("DD-MM-YYYY")}
</td>
<td>
{d.booking_id && moment(d.booking_id.rental_period[1]).format("DD-MM-YYYY")}
</td>
<td>
{d.booking_id?.quantity}
</td>
<td>
{d.booking_id?.shift}
</td>
</tr>
))}
</tbody>
</Table>
</Col></Row></Col> */}

                  <DataTable
                    data={data}
                    columns={tableColumns}
                    center={true}
                    pagination
                    // onChangePage={pagination}
                    // selectableRows
                    // onSelectedRowsChange={handleRowSelected}
                    // clearSelectedRows={toggleCleared}
                  />
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      )}
    </Fragment>
  );
};
export default Enquiries;
