export const positionAsia = [40.0479, 99.6197]
export const centerAsia = [66.0479, 100.617]
export const rectangleAsia = [
    [55.0479, 53.6197],
    [51.5, 79.06],
]
export const polylineAsia = [
    [61.1, 52.448],
    [51.23, 66.53],
    [41.254, 78.6197],
]
export const multiPolylineAsia = [
    [
        [24.415, 83.565],
        [63.65, 76.88],
        [51.2, 66.33],
    ],
    [
        [33.2, 83.2],
        [52.8, 86.5],
        [65.32, 96.55],
    ],
]

export const centerAustralia = [-25.2744, 130.7751]
export const rectangleAustralia = [
    [-23.2744, 132.7751],
    [-30.2744, 144.7751],
]

export const positionCanada = [42.505, -80.3468]
export const centerCanada = [39.505, -70.3468]
export const rectangleCanada = [
    [51.49, -0.08],
    [51.5, -0.06],
]

export const positionIndia = [20.5937, 78.9629]
export const boundsIndia = [
    [21.5937, 80.9629],
    [22.5937, 70.9629],
]

export const positionUSA = [51.505, -0.09]