import axios from 'axios';
import store from '../store';
import { API_BASE_URL } from './url';


export const instance = axios.create({
    baseURL: API_BASE_URL,
    timeout: 3000,
    headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
    },
});


instance.interceptors.request.use(function (config) {
    let user = localStorage.getItem("userObj");
    return {
        ...config,
        headers: {
            Authorization: user,
        },
    };
});

const responseBody = (response) => response.data;

const error = (error) => {
    if (error.response) {
        // console.log('COODE', error.response.status);
        if (error.response.status == 401) {
            localStorage.removeItem("userObj");
            localStorage.removeItem("userDetails");
            window.location.replace(window.location.origin);
        }
        return { status: false, message: error.response.data }
    } else if (error.request) {
        return { status: false, message: 'Invalid request' };
    }

    return { status: false, message: error };
};

const requests = {
    get: (url, body, headers) =>
        instance.get(url, body, headers).then(responseBody).catch(error),


    post: (url, body, headers) =>
        instance.post(url, body, headers).then(responseBody).catch(error),

    put: (url, body, headers) =>
        instance.put(url, body, headers).then(responseBody).catch(error),

    patch: (url, body) => instance.patch(url, body).then(responseBody).catch(error),

    delete: (url) => instance.delete(url).then(responseBody).catch(error),
};

export default requests;

