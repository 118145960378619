import React, { Fragment, useState } from "react";
import { useEffect } from "react";
import adminServices from "../../api/adminService";
import { Row, Col, Card, CardBody, CardHeader, Button, Table } from "reactstrap";
import { Container } from "react-bootstrap";
import DataTable from "react-data-table-component";
import SweetAlert from 'sweetalert2'
import { useNavigate } from "react-router";
import Breadcrumb from "../../layout/breadcrumb";
import { classes } from '../../data/layouts'
import moment from "moment/moment";
import { useLocation } from "react-router";

const BookingEnquiriesList = () => {

    const [data, setData] = useState([])
    const location = useLocation();
    const booking_id = location.state._id;

    const [loading, setLoading] = useState(true);

    const history = useNavigate();
    const defaultLayoutObj = classes.find(item => Object.values(item).pop(1) === 'compact-wrapper');
    const layout = localStorage.getItem('layout') || Object.keys(defaultLayoutObj).pop();


    useEffect(() => {
        async function getBookingEnquiries() {

            const res = await adminServices.getBookingEnquiries(
                booking_id
            );
            setLoading(false);
            if (res.status) {
                setData(res.data)
            }
            else {
                setData([]);
            }

        }
        getBookingEnquiries()

    }, [0])






    const tableColumns = [
        {
            name: 'BRAND',
            selector: row => row.listing_id.brand,
            sortable: true
        },
        {
            name: 'Mfg Year',
            selector: row => row.listing_id.mfg_year,
            sortable: true,
            center: true,
        },
        {
            name: 'Status',
            selector: row => row.status,
            center: true,
        },
        // {
        //     name: 'Backpage',
        //     selector: row => row.backpage,
        //     sortable: true,
        //     center: true,
        // },
        // {
        //     name: 'Action',
        //     selector: row => {
        //         return <div>
        //             <span><i className="fa fa-pencil" style={{ width: 35, fontSize: 16, padding: 11, color: 'rgb(40, 167, 69)' }} onClick={() => onUpdateDocument(row)}></i></span>
        //             <span><i className="fa fa-trash" style={{ width: 35, fontSize: 16, padding: 11, color: '#e4566e' }} onClick={() => onRemoveDocument(row._id)}></i></span>
        //         </div>
        //     },
        //     sortable: true,
        //     center: true,
        // },
    ]




    return (
        <Fragment>
            <Breadcrumb parent="Table" title="Enquiries" />
            <Container fluid={true}>
                <Row>
                    <Col sm="12">
                        <Card>

                            <CardBody>
                                <DataTable
                                    data={data}
                                    columns={tableColumns}
                                    center={true}

                                />

                            </CardBody>


                        </Card>
                    </Col>
                </Row>
            </Container>
        </Fragment>
    )

}
export default BookingEnquiriesList;