import React, { Fragment, useState } from "react";
import { useEffect } from "react";
import adminServices from "../../api/adminService";
import {
  Row,
  Col,
  Card,
  Button,
  Modal,
  ModalBody,
  ModalFooter,
  Form,
  ModalHeader,
  Label,
  Input,
  FormGroup,
  FormFeedback,
  CardTitle,
} from "reactstrap";
import { Container } from "react-bootstrap";
import SweetAlert from "sweetalert2";
import { useNavigate } from "react-router";
import Breadcrumb from "../../layout/breadcrumb";
import { classes } from "../../data/layouts";
import Select from "react-select";
import { useLocation } from "react-router";
import { toast } from "react-toastify";
import Loading from "./Loading";

const OtpByPass = (props) => {
  const [data, setData] = useState("");
  const [VaryingContentone, setVaryingContentone] = useState(false);
  const [VaryingContent, setVaryingContent] = useState(false);
  const [value, setValue] = useState("");
  const [num, setEnum] = useState("");
  const [error, setError] = useState("");
  const [selectedRule, SetSelectedRule] = useState({});
  const [load, setLoad] = useState(true);
  const [mobile, setMobile] = useState("");

  console.log(mobile);

  async function OtpByPass() {
    setLoad(true);

    const res = await adminServices.getOtpByPassData();
    console.log(res);
    if (res.status) {
      setLoad(false);
      setData(res.data);
    } else {
      setData("");
    }
  }

  useEffect(() => {
    OtpByPass();
  }, []);

  console.log(data);

  const displayModel = (r) => {
    if (r) {
      SetSelectedRule(r);
      setMobile(r.mobile);

      setVaryingContentone(!VaryingContentone);
    } else {
      setVaryingContentone(!VaryingContentone);
      setMobile("");
    }
  };

  const selectDropdown = (event) => {
    setEnum(event.value);
  };

  const selectValue = (event) => {
    setValue(event.value);
  };
  const onRemoveOtp = (id) => {
    SweetAlert.fire({
      title: "Are you sure?",
      text: "Once deleted, you will not be able to recover this Number",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Ok",
      cancelButtonText: "cancel",
      reverseButtons: true,
    }).then((result) => {
      if (result.value) {
        const res = adminServices.removeOtpByPassData(id);
        console.log(res);
        SweetAlert.fire("Deleted!", "Your Number has been deleted.", "success");
        OtpByPass();
      } else {
        SweetAlert.fire("Your file is safe!");
      }
      OtpByPass();
    });
  };

  const onSave = async () => {
    const res = await adminServices.addOtpByPassData({ mobile: mobile });
    setLoad(true);
    if (res.status) {
      setLoad(false);
      toast(res.message);
      OtpByPass();
      // setMobile("");

      displayModel();
    } else {
      toast.error(res.message);
    }
  };
  //   };

  const displayRulesModel = () => {
    setVaryingContent(!VaryingContent);
  };

  return (
    <Fragment>
      <Breadcrumb
        hideLinks={true}
        parent="Tables"
        title="Otp ByPass"
        renderOptions={() => {
          return (
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "flex-end",
              }}
            >
              <div>
                <Button color="primary" onClick={() => displayModel()}>
                  {"Add"}
                </Button>
              </div>
            </div>
          );
        }}
      />
      {load ? (
        <Loading />
      ) : (
        <Container fluid={true}>
          {
            <Row>
              {data &&
                data?.map((r) => (
                  <Col sm="6" xl="4" md="6" key={r.rule}>
                    <Card body outline color="secondary">
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          justifyContent: "space-between",
                        }}
                      >
                        <div>
                          <CardTitle>Mobile : {r.mobile}</CardTitle>
                        </div>

                        <div>
                          <div>
                            <i
                              className="fa fa-trash"
                              style={{
                                cursor: "pointer",
                                width: 35,
                                fontSize: 16,
                                padding: 11,
                                color: "#e4566e",
                              }}
                              onClick={() => onRemoveOtp(r._id)}
                            ></i>
                          </div>
                        </div>
                      </div>
                    </Card>
                  </Col>
                ))}
            </Row>
          }
        </Container>
      )}
      <Container fluid={true}>
        <Row>
          <Col sm="12">
            <Card>
              <Modal isOpen={VaryingContentone} toggle={() => displayModel()}>
                <ModalHeader toggle={() => displayModel()}>
                  {"Mobile"}
                </ModalHeader>
                <ModalBody>
                  <Form>
                    <FormGroup>
                      <Label className="col-form-label" for="recipient-name">
                        {"Mobile:"}
                      </Label>
                      <Input
                        className="form-control"
                        type="text"
                        value={mobile}
                        defaultValue={""}
                        onChange={(e) => {
                          setMobile(e.target.value);
                          setError("");
                        }}
                        valid={error}
                        invalid={error}
                      />

                      <FormFeedback>{error}</FormFeedback>
                    </FormGroup>
                  </Form>
                </ModalBody>
                <ModalFooter>
                  <Button color="secondary" onClick={() => displayModel()}>
                    {"Close"}
                  </Button>
                  <Button color="primary" onClick={() => onSave()}>
                    {"Save"}
                  </Button>
                </ModalFooter>
              </Modal>
            </Card>
          </Col>
        </Row>
      </Container>

      <Container fluid={true}>
        <Row>
          <Col sm="12">
            <Card>
              <Modal isOpen={VaryingContent} toggle={() => displayRulesModel()}>
                <ModalHeader toggle={() => displayRulesModel()}>
                  {"Rules"}
                </ModalHeader>
                <ModalBody>
                  <Form>
                    <FormGroup>
                      <Label className="col-form-label" for="recipient-name">
                        {"Rule:"}
                      </Label>
                      {selectedRule.mobile}
                    </FormGroup>
                    \
                  </Form>
                </ModalBody>
                <ModalFooter>
                  <Button color="secondary" onClick={() => displayRulesModel()}>
                    {"Close"}
                  </Button>
                </ModalFooter>
              </Modal>
            </Card>
          </Col>
        </Row>
      </Container>
    </Fragment>
  );
};
export default OtpByPass;
