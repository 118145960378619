import React, { Fragment, useState, useEffect } from "react";

import {
  Container,
  Row,
  Col,
  Card,
  CardBody,
  Form,
  FormGroup,
  Label,
  Input,
  Button,
  FormFeedback,
  CardFooter,
} from "reactstrap";
import Select from "react-select";
import adminServices from "../../api/adminService";
import { toast } from "react-toastify";
import { useLocation } from "react-router";

import Breadcrumb from "../../layout/breadcrumb";
import commonServices from "../../api/commonService";
import { useNavigate } from "react-router";

const Listing = (props) => {
  const location = useLocation();
  const userId = location.state.user._id || "";
  const userData = location.state.userData;
  const updateData = location?.state?.update;
  const history = useNavigate();

  const [formValues, setFormValues] = useState({
    equipment_id: updateData?.equipment_id ? updateData.equipment_id?._id : "",
    equipment_name: updateData?.equipment_name ? updateData.equipment_name : "",
    brand: updateData?.brand ? updateData.brand : "",
    model: updateData?.model ? updateData.model : "",
    mrg_year: updateData?.mrg_year ? updateData.mrg_year : "",
    capacity: updateData?.capacity ? updateData.capacity : "",

    // usage_run: updateData?.usage_run ? updateData.usage_run : "",
    // usage_hours: updateData?.usage_hours ? updateData.usage_hours : "",
    // expected_rent: {
    //     hourly: updateData?.expected_rent?.hourly ? updateData.expected_rent?.hourly : "",
    //     daily: updateData?.expected_rent?.daily ? updateData?.expected_rent?.daily : "",
    //     weekly: updateData?.expected_rent?.weekly ? updateData?.expected_rent?.weekly : "",
    //     monthly: updateData?.expected_rent?.monthly ? updateData?.expected_rent?.monthly : "",
    //     yearly: updateData?.expected_rent?.yearly ? updateData?.expected_rent?.yearly : "",
    // }
  });

  console.log("STat", updateData);
  const [data, setData] = useState([]);
  const [mapData, setMapData] = useState();
  const [category, setCategory] = useState(
    updateData?.equipment_id ? updateData.equipment_id?.category_id : undefined
  );
  const [equipment, setEquipment] = useState();
  const [equipementValue, setEquipmentValue] = useState();

  const [userAddress, setUserAddress] = useState([]);

  const [addressId, setAddressId] = useState(updateData?.address);

  const [make, setMake] = useState([]);

  const [categoryError, setCategoryError] = useState();

  const [formErrors, setFormErrors] = useState({
    equipment_id: "",
    equipment_name: "",
    brand: "",
    model: "",
    mrg_year: "",
    capacity: "",
    // usage_run: "",
    // usage_hours: "",
    // hourly: "",
    // daily: "",
    // weekly: "",
    // monthly: "",
    // yearly: "",
  });

  useEffect(() => {
    console.log("DDDD0 ", category);
    console.log("data ", data);

    if (category) {
      let d = data.find((item) => item._id === category);
      setEquipment(d?.equipments);
    }
  }, [category, data]);

  useEffect(() => {
    async function getCategory() {
      const res = await adminServices.getCategory();

      const cat = [];
      if (res.status) {
        setData(res.data);
      }
      setMapData(cat);
    }
    getCategory();

    async function getMake() {
      const res = await commonServices.getDropdown("make");

      if (res.status) {
        setMake(res.data);
      }
    }

    getMake();

    async function getAddress() {
      const res = await adminServices.getAddress(userId);
      if (res.status) {
        setUserAddress(res.data);
      }
    }

    getAddress();
  }, [0]);

  const onSubmit = async () => {
    let errors = {};
    // if (category === "Please Select") {
    //     setCategoryError("Please select the Category")

    // }
    // const expecte = {
    //     hourly: formValues.hourly,
    //     daily: formValues.daily,
    //     weekly: formValues.weekly,
    //     monthly: formValues.monthly,
    //     yearly: formValues.yearly,
    // }
    if (updateData) {
      const res = await adminServices.updateListing(updateData._id, {
        ...formValues,
        //  expected_rent: expecte,
        address: addressId,
      });
      console.log("res1", res);

      toast(res.message);

      history(-1);

      // history(`${process.env.PUBLIC_URL}/app/listingdata/${userId}`, { state: userData })
    } else {
      const res = await adminServices.addListing(
        {
          ...formValues,
          // expected_rent: expecte,
          address: addressId,
        },
        userId
      );

      if (res.status) {
        toast(res.message);
        //  history(`${process.env.PUBLIC_URL}/app/listingdata/${res?.data?._id}`);
        history(`${process.env.PUBLIC_URL}/app/listingdata/${userId}`, {
          state: userData,
        });
      } else {
        toast.error(res.message);
      }
    }
  };

  const onCategoryChange = (e) => {
    setCategory(e.target.value);
    let d = data.find((item) => item._id === e.target.value);
    setEquipment(d.equipments);
  };

  const handleOnChange = (event) => {
    setFormValues({ ...formValues, [event.target.name]: event.target.value });
    // setFormErrors({ ...formErrors, [event.target.name]: "" });
  };
  const addressHandler = (id) => {
    setAddressId(id);
  };

  return (
    <Fragment>
      <Breadcrumb title="Listing" />

      <Row>
        <Col sm="12">
          <Card>
            <CardBody>
              <Form className="theme-form">
                <h5 style={{ marginVertical: "10px" }}>Equipment Details</h5>

                <Row>
                  <Col sm="4">
                    <FormGroup className="m-t-1">
                      <Label>{"Category"}</Label>

                      <Input
                        type="select"
                        name="category"
                        onChange={(e) => onCategoryChange(e)}
                        valid={formErrors.category}
                        invalid={formErrors.category}
                        value={category}
                        className="form-control"
                      >
                        <option value={""}>{"Please Select"}</option>

                        {data?.map((d) => {
                          return <option value={d._id}>{d.title}</option>;
                        })}
                      </Input>
                    </FormGroup>
                  </Col>

                  <Col sm="4">
                    <FormGroup className="m-t-1">
                      <Label>{"Equipment  "}</Label>
                      <Input
                        className="form-control"
                        type="select"
                        name="equipment_id"
                        value={formValues.equipment_id}
                        defaultValue=""
                        valid={formErrors.equipment_id}
                        invalid={formErrors.equipment_id}
                        onChange={(e) => handleOnChange(e)}
                        required
                        placeholder={"Equipment Name"}
                      >
                        <option value={""}>{"Please Select"}</option>
                        {equipment?.map((e) => {
                          return <option value={e._id}>{e.title}</option>;
                        })}
                      </Input>
                      <FormFeedback>{formErrors.equipment_id}</FormFeedback>
                    </FormGroup>
                  </Col>
                  <Col sm="4">
                    <FormGroup className="m-t-1">
                      <Label>{"Equipment  Name"}</Label>
                      <Input
                        className="form-control"
                        type="text"
                        name="equipment_name"
                        value={formValues.equipment_name}
                        defaultValue=""
                        valid={formErrors.equipment_name}
                        invalid={formErrors.equipment_name}
                        onChange={(e) => handleOnChange(e)}
                        required
                        placeholder={"Equipment Name"}
                      />
                      <FormFeedback>{formErrors.equipment_name}</FormFeedback>
                    </FormGroup>
                  </Col>
                  <Col sm="4">
                    <FormGroup className="m-t-1">
                      <Label>{"Brand"}</Label>
                      <Input
                        type="select"
                        name="brand"
                        placeholder={"Brand"}
                        onChange={(e) => handleOnChange(e)}
                        valid={formErrors.brand}
                        invalid={formErrors.brand}
                        value={formValues.brand}
                        className="form-control digits"
                      >
                        <option value={""}>{"Please Select"}</option>
                        {make.map((d) => {
                          return (
                            <option value={d.value ? d.value : d.title}>
                              {d.title}
                            </option>
                          );
                        })}
                        {/* <option value={"Jcb"}>{"Jcb"}</option>
                                                <option value={""}>{""}</option> */}
                      </Input>
                      <FormFeedback>{formErrors.brand}</FormFeedback>
                    </FormGroup>
                  </Col>

                  <Col sm="4">
                    <FormGroup className="m-t-1">
                      <Label>{"Model"}</Label>
                      <Input
                        className="form-control"
                        type="text"
                        name="model"
                        required="true"
                        placeholder={"Model"}
                        valid={formErrors.model}
                        value={formValues.model}
                        invalid={formErrors.model}
                        onChange={(e) => handleOnChange(e)}
                      />
                      <FormFeedback>{formErrors.model}</FormFeedback>
                    </FormGroup>
                  </Col>

                  <Col sm="4">
                    <FormGroup className="m-t-1">
                      <Label>{"Manufacturing Year"}</Label>
                      <Input
                        className="form-control"
                        type="number"
                        name="mrg_year"
                        placeholder={"YYYY"}
                        onChange={(e) => handleOnChange(e)}
                        maxLength={4}
                        value={formValues.mrg_year}
                        valid={formErrors.mrg_year}
                        invalid={formErrors.mrg_year}
                      />
                      <FormFeedback>{formErrors.mrg_year}</FormFeedback>
                    </FormGroup>
                  </Col>
                  <Col sm="4">
                    <FormGroup className="m-t-1">
                      <Label>{"Capacity"}</Label>
                      <Input
                        className="form-control"
                        type="text"
                        name="capacity"
                        placeholder={"capacity"}
                        onChange={(e) => handleOnChange(e)}
                        // maxLength={4}

                        value={formValues.capacity}
                        valid={formErrors.capacity}
                        invalid={formErrors.capacity}
                      />
                      <FormFeedback>{formErrors.capacity}</FormFeedback>
                    </FormGroup>
                  </Col>
                </Row>

                {/* <h5 style={{ marginVertical: '10px' }}>Usage</h5>
                                <Row>
                                    <Col sm="4">
                                        <FormGroup className='m-t-1'>
                                            <Label>{"Killometers"}</Label>
                                            <Input
                                                className="form-control"
                                                type="number"
                                                name="usage_run"
                                                value={formValues.usage_run}

                                                valid={formErrors.usage_run}
                                                invalid={formErrors.usage_run}
                                                onChange={(e) => handleOnChange(e)}
                                                required
                                                placeholder={"Killometers"}


                                            />
                                            <FormFeedback>
                                                {
                                                    formErrors.usage_run
                                                }
                                            </FormFeedback>
                                        </FormGroup>
                                    </Col>

                                    <Col sm="4">
                                        <FormGroup className='m-t-1'>
                                            <Label>{"Hours"}</Label>
                                            <Input className="form-control"
                                                type="number"
                                                name="usage_hours"
                                                placeholder={"Hours"}
                                                onChange={(e) => handleOnChange(e)}

                                                value={formValues.usage_hours}
                                                valid={formErrors.usage_hours}
                                                invalid={formErrors.usage_hours}

                                            />
                                            <FormFeedback>
                                                {
                                                    formErrors.usage_hours
                                                }
                                            </FormFeedback>
                                        </FormGroup>
                                    </Col>
                                </Row> */}
                {/* <h5 style={{ marginVertical: '10px' }}>Expected Rent</h5> */}
                {/* <Row>
                                    <Col sm="4">
                                        <FormGroup className='m-t-1'>
                                            <Label>{"Hourly Rent"}</Label>
                                            <Input className="form-control"
                                                type="number"
                                                name="hourly"
                                                required="true"
                                                placeholder={"Hourly Rent"}
                                                valid={formErrors.hourly}
                                                value={formValues.hourly}
                                                invalid={formErrors.hourly}
                                                onChange={(e) => handleOnChange(e)}
                                            />
                                            <FormFeedback>
                                                {
                                                    formErrors.model
                                                }
                                            </FormFeedback>
                                        </FormGroup>
                                    </Col>
                                    <Col sm="4">
                                        <FormGroup className='m-t-1'>
                                            <Label>{"Daily Rent"}</Label>
                                            <Input className="form-control"
                                                type="number"
                                                name="daily"
                                                required="true"
                                                placeholder={"Daily Rent"}
                                                valid={formErrors.daily}
                                                value={formValues.daily}
                                                invalid={formErrors.daily}
                                                onChange={(e) => handleOnChange(e)}
                                            />
                                            <FormFeedback>
                                                {
                                                    formErrors.daily
                                                }
                                            </FormFeedback>
                                        </FormGroup>
                                    </Col>
                                    <Col sm="4">
                                        <FormGroup className='m-t-1'>
                                            <Label>{"Weekly Rent"}</Label>
                                            <Input className="form-control"
                                                type="number"
                                                name="weekly"
                                                required="true"
                                                placeholder={"Weekly Rent"}
                                                valid={formErrors.weekly}
                                                value={formValues.weekly}
                                                invalid={formErrors.weekly}
                                                onChange={(e) => handleOnChange(e)}
                                            />
                                            <FormFeedback>
                                                {
                                                    formErrors.weekly
                                                }
                                            </FormFeedback>
                                        </FormGroup>
                                    </Col>

                                    <Col sm="4">
                                        <FormGroup className='m-t-1'>
                                            <Label>{"Monthly Rent"}</Label>
                                            <Input className="form-control"
                                                type="number"
                                                name="monthly"
                                                required="true"
                                                placeholder={"Monthly Rent"}
                                                valid={formErrors.monthly}
                                                value={formValues.monthly}
                                                invalid={formErrors.monthly}
                                                onChange={(e) => handleOnChange(e)}
                                            />
                                            <FormFeedback>
                                                {
                                                    formErrors.monthly
                                                }
                                            </FormFeedback>
                                        </FormGroup>
                                    </Col>
                                    <Col sm="4">
                                        <FormGroup className='m-t-1'>
                                            <Label>{"Yearly Rent"}</Label>
                                            <Input className="form-control"
                                                type="number"
                                                name="yearly"
                                                required="true"
                                                placeholder={"Yearly Rent"}
                                                valid={formErrors.yearly}
                                                value={formValues.yearly}
                                                invalid={formErrors.yearly}
                                                onChange={(e) => handleOnChange(e)}
                                            />
                                            <FormFeedback>
                                                {
                                                    formErrors.yearly
                                                }
                                            </FormFeedback>
                                        </FormGroup>
                                    </Col>
                                </Row> */}

                <h5 style={{ marginVertical: "10px" }}>Address</h5>

                <Row>
                  {userAddress.map((d) => {
                    return (
                      <Col onClick={() => addressHandler(d._id)} sm={4}>
                        <Card
                          style={{
                            padding: "10px",
                            // borderStyle: addressId == d._id  ? "solid" : "",  borderColor: addressId == d._id  ? "blue" : "",
                            //   backgroundColor:addressId === d._id  ? "gray" :addressId == d._id ? "white":""}}>
                            backgroundColor:
                              addressId === d._id ? "#aac9e8" : "white",
                          }}
                        >
                          <h6
                            style={{ color: addressId == d._id ? "blue" : "" }}
                          >
                            {d.name}
                          </h6>
                          <p>{d.complete_address}</p>
                          <p>{d.pin_code}</p>
                        </Card>
                      </Col>
                    );
                  })}
                </Row>
                <Row>
                  <Col>
                    <FormGroup>
                      <Button
                        color="primary"
                        className="me-3 m-t-1"
                        onClick={() => onSubmit()}
                      >
                        {"Save"}
                      </Button>
                    </FormGroup>
                  </Col>
                </Row>
              </Form>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </Fragment>
  );
};

export default Listing;
