import React from 'react';

import { LOGOUT, LOGGED_IN } from '../actionTypes'
const { All, Doing, Done } = require('../../data/project')


const initial_state = {

    user: undefined,
    loginStatus: false,


}


const authReducer = (state = initial_state, action) => {

    switch (action.type) {


        case LOGGED_IN:

            return {
                ...state,
                loginStatus: true,
                user: action.payload,


            };

        case LOGOUT: {
            return {
                ...state,
                loginStatus: false,
                user: {},

            }
        }



        default:
            return state;
    }

}
export default authReducer