import { LOGGED_IN, LOGOUT } from '../actionTypes';


export const loginSuccess = (data) => {
    return {
        type: LOGGED_IN,
        payload: data
    }
}

export const logoutUser = () => {
    return {
        type: LOGOUT
    }
}

