import React, { Fragment, useState } from "react";
import { useEffect } from "react";
import adminServices from "../../api/adminService";
import { Row, Col, Card, CardBody, CardHeader, Button } from "reactstrap";
import { Container } from "react-bootstrap";
import DataTable from "react-data-table-component";
import SweetAlert from 'sweetalert2'
import { useNavigate } from "react-router";
import Breadcrumb from "../../layout/breadcrumb";
import { classes } from '../../data/layouts'


const Admin = () => {

    const [data, setData] = useState("")
    const [selectedRows, setSelectedRows] = useState([]);
    const [toggleCleared, setToggleCleared] = useState(false);
    const history = useNavigate();
    const defaultLayoutObj = classes.find(item => Object.values(item).pop(1) === 'compact-wrapper');
    const layout = localStorage.getItem('layout') || Object.keys(defaultLayoutObj).pop();


    async function getUsers() {

        const res = await adminServices.getUsers("Admin");
        if (res.status) {
            setData(res.data)
        }
        else {
            setData("")
        }

    }
    useEffect(() => {
        getUsers()

    }, [0])



    const tableColumns = [
        {
            name: 'ID',
            selector: row => row._id,
            sortable: true,

        },
        {
            name: 'Name',
            selector: row => row.name,
            sortable: true,

        },
        {
            name: 'Email',
            selector: row => row.email,
            sortable: true,
            center: true,
        },
        {
            name: 'Mobile Number',
            selector: row => row.mobile,
            sortable: true,
            center: true,
        },
        {
            name: 'Type',
            selector: row => row.type,
            sortable: true,
            center: true,
        },
        {
            name: 'Action',
            selector: row => {
                return <div>
                    <span><i className="fa fa-pencil" style={{ width: 35, fontSize: 16, padding: 11, color: 'rgb(40, 167, 69)' }} onClick={() => onUpdateUser(row)}></i></span>
                    <span><i className="fa fa-trash" style={{ width: 35, fontSize: 16, padding: 11, color: '#e4566e' }} onClick={() => onRemoveUser(row.mobile)}></i></span>
                </div>
            },
            sortable: true,
            center: true,
        },
    ]


    const onRemoveUser = (id) => {
        console.log(id)
        SweetAlert.fire({
            title: 'Are you sure?',
            text: "Once deleted, you will not be able to recover this user!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Ok',
            cancelButtonText: 'cancel',
            reverseButtons: true
        }).then((result) => {
            if (result.value) {
                adminServices.deleteUser(id)
                SweetAlert.fire(
                    'Deleted!',
                    'Your User has been deleted.',
                    'success'
                )
                getUsers()
                    //   history(`${process.env.PUBLIC_URL}/app/users/Dubai`);
                    ;
            }
            else {
                SweetAlert.fire(
                    'Your file is safe!'
                )
            }
        })

    }
    const onUpdateUser = (row) => {
        console.log(row)
        history(`${process.env.PUBLIC_URL}/app/user/${row._id}`, { state: row });
    }


    return (
        <Fragment>
            <div style={{ display: "flex", flexDirection: "row", justifyContent: "flex-end" }}>

                <Breadcrumb parent="Table" title="Admin" />
                <Button style={{ width: "8%", height: "10%", marginTop: "2%" }} color="primary" onClick={() =>
                    history(`${process.env.PUBLIC_URL}/app/user/${layout}`)
                }>{"Add"}</Button>
            </div>

            <Container fluid={true}>
                <Row>
                    <Col sm="12">
                        <Card>
                            <CardBody>
                                <DataTable
                                    data={data}
                                    columns={tableColumns}
                                    center={true}
                                />
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </Container>
        </Fragment>
    )

}
export default Admin;