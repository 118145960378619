import React, { Fragment, useState } from "react";
import { useEffect } from "react";
import adminServices from "../../api/adminService";
import { Row, Col, Card, CardBody, CardHeader, Button, Modal, ModalBody, ModalFooter, Form,
    ModalHeader, Label, Input, FormGroup, FormFeedback } from "reactstrap";
import { Container } from "react-bootstrap";
import DataTable from "react-data-table-component";
import SweetAlert from 'sweetalert2'
import { useLocation, useNavigate } from "react-router";
import Breadcrumb from "../../layout/breadcrumb";

import { classes } from '../../data/layouts'
import Loading from "./Loading";
import { toast } from "react-toastify";



const DropDownList = () => {


    const [data, setData] = useState("");
    const [loader,setLoader] = useState(false)
    const [VaryingContentone, setVaryingContentone] = useState(false);
    const [title,setTitle]=useState("")
    const [type,setType] = useState("")
    const [value,setValue] = useState("")
    const [selectedItem,setSelectedItem]=useState("")

    const history = useNavigate();
    const defaultLayoutObj = classes.find(item => Object.values(item).pop(1) === 'compact-wrapper');
    const layout = localStorage.getItem('layout') || Object.keys(defaultLayoutObj).pop();
    const [dropValues, setDropValues] = useState();


    useEffect(() => {

        let dropData = [];
        for (var k in data) {

            dropData.push(data[k])
        }
        setDropValues(dropData)

    }, [data])

   

    const onSave = async () => {


        // let errors = {};
        // if (!formValues.title) {
        //     errors.title = "Please enter the title";
        // }

        // if (!formValues.type) {
        //     errors.type = "Please select the type";
        // }

        // if (Object.keys(errors).length > 0) {
        //     setFormErrors(errors);

        //     return;
        // }

        if (selectedItem) {
            const res = await adminServices.updateDropDown(selectedItem._id, {
                title: title,
                value: value
            }, type.toLowerCase())
            if (res.status) {
                toast(res.message);
                displayModel()
                setTitle("");
                setType("");
                setValue("")
                getDropDowns()
            } else {
                toast.error(res.message)
            }

        } else {

            const res = await adminServices.dropDown({
                title: title,
                value: value
            }, type.toLowerCase())
            if (res.status) {
                toast(res.message);
                displayModel()
                setTitle("");
                setType("");
                setValue("")
                getDropDowns()
            } else {
                toast.error(res.message)
            }
        }

    }

    async function getDropDowns() {
        setLoader(true)
            const res = await adminServices.getDropdowns();

            if (res.status) {
                setLoader(false)
                setData(res.data);

            }
            else {
                setData("")
            }
            
            
        }
        useEffect(() => {

        getDropDowns()

    }, [0])






    const tableColumns = [
        {
            name: 'Title',
            selector: row => row.title,
            sortable: true,

        },
        {
            name: 'Type',
            selector: row => row.type,

            center: true,
        },
        {
            name: 'Value',
            selector: row => row.value,

            center: true,
        },
        {
            name: 'Action',
            selector: row => {
                return <div>
                    <span><i className="fa fa-pencil" style={{ width: 35, fontSize: 16, padding: 11, color: 'rgb(40, 167, 69)' }} onClick={() => displayModel(row)}></i></span>
                    <span><i className="fa fa-trash" style={{ width: 35, fontSize: 16, padding: 11, color: '#e4566e' }} onClick={() => onRemoveDropDown(row._id)}></i></span>
                </div>
            },

            center: true,
        },



    ]


    const onRemoveDropDown = (id) => {
        SweetAlert.fire({
            title: 'Are you sure?',
            text: "Once deleted, you will not be able to recover this user!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Ok',
            cancelButtonText: 'cancel',
            reverseButtons: true
        }).then((result) => {
            if (result.value) {
                adminServices.deleteDropDown(id)
                SweetAlert.fire(
                    'Deleted!',
                    'Your Ttile has been deleted.',
                    'success'
                )
                getDropDowns()
            }

            
            else {
                SweetAlert.fire(
                    'Your file is safe!'
                )
            }
        })

    }
    // const onUpdateDropDown = (row) => {
    //     history(`${process.env.PUBLIC_URL}/app/dropdown/${row._id}`, { state: row });
    // }

    // const addDropdown = () => {
    //     history(`${process.env.PUBLIC_URL}/app/dropdown/${layout}`);
    // }
    
     const displayModel = (row) =>{
        if (row) {
            setSelectedItem(row);
            setTitle(row.title);
            setType(row.type);
            setValue(row.value)
            setVaryingContentone(!VaryingContentone);
          } else {
            setVaryingContentone(!VaryingContentone);
            setTitle("");
            setType("");
            setValue("")
          }
     }


    return (
        <Fragment>
           
             <div style={{ display: "flex", flexDirection: "row", justifyContent: "flex-end" }}>
        
        <Breadcrumb  parent="Table" title="DropDowns"/>
        <Button  style={{height:"10%",marginTop:"2%"}}  color="primary"  onClick={() =>
                // addDropdown()
                displayModel()
                }>{"Add"}</Button>
        </div>
            {loader ? <Loading /> : 
            <Container fluid={true}>
                <Row>
                    <Col sm="12">

                        {
                          data.length === 0 ? 
                          <Card >
                            <CardBody>
                            <h6 className='text-center' >There are no records to display</h6>
                            </CardBody>
                          </Card>
                          
                          :  dropValues?.map((item) => {
                                return <Card>
                                    {/*<CardHeader style={{ display: "flex", flexDirection: "row", justifyContent: "space-between" }}>
                                    
                                    
                                </CardHeader>*/}
                                <h5 style={{paddingTop:"3%",paddingLeft:"3%"}}>{item._id}</h5>
                                    <CardBody>

                                        <DataTable
                                            data={item.data}
                                            columns={tableColumns}
                                            center={true}

                                        />
                                    </CardBody>
                                </Card>

                            })
                        }

                    </Col>
                </Row>
            
           
        <Row>
          <Col sm="12">
            <Card>
              <Modal isOpen={VaryingContentone} toggle={() => displayModel()}>
                <ModalHeader toggle={() => displayModel()}>
                  {"Category"}
                </ModalHeader>
                <ModalBody>
                  <Form>
                    <FormGroup>
                      <Label className="col-form-label" for="recipient-name">{"Title:"}</Label>
                   

                      <Input
                                                className="form-control"
                                                type="text"
                                                name="title"
                                                value={title}
                                                defaultValue=""
                                                // valid={formErrors.title}
                                                // invalid={formErrors.title}
                                                onChange={(e) => setTitle(e.target.value)}

                                                required
                                                placeholder={"Title"}


                                            />


                      {/* <FormFeedback>{error}</FormFeedback> */}

                    </FormGroup>
                    <FormGroup>
                      <Label className="col-form-label" for="message-text">{"Type:"}</Label>
                    
                                               <Input className="form-control"
                                                type="text"
                                                name="type"
                                                placeholder={"Type"}
                                                onChange={(e) => setType(e.target.value)}
                                                required="true"
                                                value={type}
                                                // valid={formErrors.type}
                                                // invalid={formErrors.type}

                                            />
                    </FormGroup>
                    <FormGroup>
                      <Label className="col-form-label" for="recipient-name">{"Value:"}</Label>

                      <Input className="form-control"
                                                type="text"
                                                name="value"
                                                placeholder={"Value"}
                                                onChange={(e) => setValue(e.target.value)}

                                                required="true"
                                                value={value}


                                            />
                    </FormGroup>

                  </Form>
                </ModalBody>
                <ModalFooter>
                  <Button color="secondary" onClick={() => displayModel()}>
                    {"Close"}
                  </Button>
                  <Button color="primary" onClick={() => onSave()}>
                    {"Save"}
                  </Button>
                </ModalFooter>
              </Modal>

              <CardBody>
                <DataTable data={data} columns={tableColumns} center={true} />
              </CardBody>
            </Card>
          </Col>
        </Row>
        </Container>}
        </Fragment>
    )

}
export default DropDownList;