import React from "react";
import { Fragment } from "react";
import Breadcrumb from "../../layout/breadcrumb";
import { Row, Col, Card, CardBody, CardFooter, Form, FormGroup, Label, Input, FormFeedback, Button } from "reactstrap";
import { useState,useEffect } from "react";
import adminServices from "../../api/adminService";
import { useLocation, useNavigate } from "react-router";
import { toast } from "react-toastify";
import { API_BASE_URL } from "../../api/url";


const SaveEquipment = () => {
  
    const location = useLocation();
    const { category, equipment, isEdit } = location.state;
    const [data,setData]=useState([])

    const history = useNavigate();

    const [formValues, setFormValues] = useState({
        title: equipment?.title ? equipment.title : "",
        description: equipment?.description ? equipment.description : ""
    })

    const [selectedIcon, setSelectedIcon] = useState(
        "");
    const [formErrors, setFormErrors] = useState({
        title: "",
        description: ""
    })
    const handleOnChange = (event) => {
        setFormValues({ ...formValues, [event.target.name]: event.target.value });
        setFormErrors({ ...formErrors, [event.target.name]: "" });
    }
    
async function getCategory() {
  const res = await adminServices.getCategory();
  if (res.status) {
    setData(res.data);
  }
}
useEffect(() => {
  getCategory();
}, [0]);

  

    const onSubmit = async () => {


        let errors = {};
        if (!formValues.title) {
            errors.title = "Please enter the title";
        }

        // if (!formValues.description) {
        //     errors.description = "Please enter the description";
        // }

        if (Object.keys(errors).length > 0) {
            setFormErrors(errors);

            return;
        }

        const formData = new FormData();
        formData.append("title", formValues.title);
        formData.append("status", category.status);
        formData.append("icon", selectedIcon);
        formData.append("description", formValues.description);




        if (equipment) {
            const res = await adminServices.updateEquipment(equipment._id, formData);

            if (res.status) {
                toast(res.message)
                history(`${process.env.PUBLIC_URL}/app/equipments/${category._id}`, { state: category });
            } else {
                toast.error(res.message)
            }
        } else {
            const res = await adminServices.addEquipment(category._id, formData);
            if (res.status) {
                toast(res.message)
                history(
                  `${process.env.PUBLIC_URL}/app/equipments/${category._id}`,
                  { state: category }
                );
            } else {
                toast.error(res.message)
            }
        }




    }





    let icon = ""

    if (selectedIcon) {
        icon = URL.createObjectURL(selectedIcon);
    }
    else if (equipment?.icon) {
        icon = API_BASE_URL + equipment.icon;
    }


    return <Fragment>

        <Breadcrumb title="Equipment" />

        <Row>
            <Col sm="12">
                <Card>
                    <CardBody>
                        <Form className="theme-form">
                            <Row>
                                <Col sm="12">

                                    <FormGroup className='m-t-1'>
                                        <Label>{"Title"}</Label>
                                        <Input
                                            className="form-control"
                                            type="text"
                                            name="title"
                                            value={formValues.title}
                                            defaultValue=""
                                            valid={formErrors.title}
                                            invalid={formErrors.title}
                                            onChange={(e) => handleOnChange(e)}
                                            required
                                            placeholder={"Title"}


                                        />
                                        <FormFeedback>
                                            {
                                                formErrors.title
                                            }
                                        </FormFeedback>
                                    </FormGroup>
                                </Col>


                                <Col sm="12">
                                    <FormGroup className='m-t-1'>

                                        <Label className="col-form-label" for="recipient-name">{"Icon:"}</Label>

                                        <img
                                            width={100}
                                            height={100}
                                            style={{ marginLeft: 10 }}
                                            src={icon}

                                        />

                                        <input
                                            style={{ marginTop: 10 }}
                                            onChange={(e) => setSelectedIcon(e.target.files[0])}
                                            // value={selectedIcon}
                                            defaultValue={""}
                                            accept="image/*"
                                            className="form-control" type="file" />


                                    </FormGroup>
                                </Col>

                                <Col sm="12">
                                    <FormGroup className='m-t-1'>
                                        <Label>{"Description"}</Label>
                                        <Input type="textarea"
                                            className="form-control"
                                            rows="4"
                                            name="description"
                                            required="true"
                                            placeholder={"Description"}
                                            valid={formErrors.description}
                                            value={formValues.description}
                                            invalid={formErrors.description}
                                            onChange={(e) => handleOnChange(e)}
                                        />
                                        <FormFeedback>
                                            {
                                                formErrors.description
                                            }
                                        </FormFeedback>
                                    </FormGroup>
                                </Col>




                            </Row>



                        </Form>
                    </CardBody>
                    <CardFooter>
                        <Button color="primary" className="me-3 "
                            onClick={() => onSubmit()}
                        >{"Save"}</Button>
                    </CardFooter>
                </Card>
            </Col>
        </Row>

    </Fragment>
}

export default SaveEquipment;