import React, { Fragment, useState } from "react";
import { useEffect } from "react";
import adminServices from "../../api/adminService";
import { Row, Col, Card, CardBody, CardHeader, Button } from "reactstrap";
import { Container } from "react-bootstrap";
import DataTable from "react-data-table-component";
import SweetAlert from "sweetalert2";
import { useLocation, useNavigate } from "react-router";
import Breadcrumb from "../../layout/breadcrumb";
import { toast } from "react-toastify";
import moment from "moment/moment";

import { classes } from "../../data/layouts";

const GetLisiting = () => {
  const location = useLocation();
  const userId = location.state;

  const [address, setAddress] = useState([]);

  const [data, setData] = useState("");
  const [selectedRows, setSelectedRows] = useState([]);
  const [toggleCleared, setToggleCleared] = useState(false);
  const [selectedListing, setSelectedListing] = useState([]);

  const history = useNavigate();
  const defaultLayoutObj = classes.find(
    (item) => Object.values(item).pop(1) === "compact-wrapper"
  );
  const layout =
    localStorage.getItem("layout") || Object.keys(defaultLayoutObj).pop();

  async function getUsers() {
    const res = await adminServices.getListing(userId._id);
    if (res.status) {
      setData(res.data);
    } else {
      setData("");
    }
  }

  async function getAddress() {
    const addres = await adminServices.getAddress(userId._id);
    if (addres.status) {
      setAddress(addres.data);
    }
  }
  useEffect(() => {
    getUsers();
    getAddress();
  }, [0]);

  const onUpdateListing = (row) => {
    history(`${process.env.PUBLIC_URL}/app/listing/${layout}`, {
      state: { update: row, user: userId },
    });
  };

  const handler = async (id) => {
    // let qryString = "";
    // qryString = new URLSearchParams({ userId: id }).toString();

    const response = await adminServices.excelListing({});
    const href = URL.createObjectURL(response);
    const link = document.createElement("a");
    link.href = href;
    link.setAttribute(
      "download",
      `listing_${moment().format("YYYY-MM-DD-HH-mm")}`
    ); //or any other extension
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
    URL.revokeObjectURL(href);
  };

  const onRemoveAddress = async (address) => {
    const res = await adminServices.deleteAddress(address._id);

    toast(res.message);

    getAddress();
  };

  const onRemoveListing = (id) => {
    SweetAlert.fire({
      title: "Are you sure?",
      text: "Once deleted, you will not be able to recover this user!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Ok",
      cancelButtonText: "cancel",
      reverseButtons: true,
    }).then((result) => {
      if (result.value) {
        const res = adminServices.updateListing(id, { isDeleted: true });
        console.log(res);
        if (res.status) {
          toast(res.message);
          getUsers();
          SweetAlert.fire("Deleted!", "Your User has been deleted.", "success");
        }

        getUsers();
      } else {
        SweetAlert.fire("Your file is safe!");
      }
      getUsers();
    });
  };

  const tableColumns = [
    {
      name: "Type",
      selector: (row) => {
        return <div>{row.equipment_id?.title}</div>;
      },
      sortable: true,
    },
    {
      name: "Name",
      selector: (row) => row.equipment_name,
      sortable: true,
    },
    {
      name: "User",
      selector: (row) => {
        let data = row.user_id.mobile;
        if (row.user_id.name) {
          data += "-" + row.user_id.name;
        }
        return data;
        // row.user_id.name + "(" + row.user_id.mobile + ")"
      },
      sortable: true,
    },
    {
      name: "Address",
      selector: (row) => {
        if (!row.address) {
          return "";
        }

        if (row.address.type) {
          return <div>{row.address.type}</div>;
        } else {
          return <div>{row.address.name - row.address.pin_code}</div>;
        }
      },

      center: true,
    },
    {
      name: "Brand",
      selector: (row) => row.brand,
      sortable: true,
      center: true,
    },
    {
      name: "Model",
      selector: (row) => row.model,
      sortable: true,
      center: true,
    },
    {
      name: "Capacity",
      selector: (row) => row.capacity,
    },
    {
      name: "createAt",
      selector: (row) => {
        return moment(row?.createdAt).format("DD-MM-YYYY");
      },
      sortable: true,
      center: true,
    },
    {
      name: "MfgYear",
      selector: (row) => row.mrg_year,
      sortable: true,
      center: true,
    },
    {
      name: "Action",
      selector: (row) => {
        return (
          <div>
            <span>
              <i
                className="fa fa-pencil"
                style={{
                  width: 35,
                  fontSize: 16,
                  padding: 11,
                  color: "rgb(40, 167, 69)",
                }}
                onClick={() => onUpdateListing(row)}
              ></i>
            </span>

            <span>
              <i
                className="fa fa-trash"
                style={{
                  width: 35,
                  fontSize: 16,
                  padding: 11,
                  color: "#e4566e",
                }}
                onClick={() => onRemoveListing(row._id)}
              ></i>
            </span>
          </div>
        );
      },

      center: true,
    },
    {
      name: "View",
      selector: (row) => {
        return (
          <div>
            <Button color="primary" onClick={() => onViewNavigate(row)}>
              View
            </Button>
          </div>
        );
      },
      sortable: true,
      center: true,
    },
  ];

  const addListing = () => {
    history(`${process.env.PUBLIC_URL}/app/listing/${layout}`, {
      state: { user: userId, userData: userId },
    });
  };

  const onViewNavigate = (row) => {
    history(`${process.env.PUBLIC_URL}/app/listing-info/${row._id} `, {
      state: row,
    });
  };

  const userStatus = async (status) => {
    const res = await adminServices.updateUserStatus(userId._id, {
      status: status,
    });

    if (res.status) {
      toast.success("Updated successfully");
    }
  };

  const handleRowSelected = (state) => {
    setSelectedListing(state.selectedRows);
  };

  const deleteListing = async () => {
    SweetAlert.fire({
      title: "Are you sure?",
      text: "Once deleted, you will not be able to recover this user!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Ok",
      cancelButtonText: "cancel",
      reverseButtons: true,
    }).then((result) => {
      if (result.value) {
        const ids = selectedListing.map((d) => d._id);
        const res = adminServices.deleteMultipleListing({ ids: ids });
        if (res.status) {
          getUsers();
          SweetAlert.fire(
            "Deleted!",
            "Your listing has been deleted.",
            "success"
          );
        }

        getUsers();
      } else {
        SweetAlert.fire("Your file is safe!");
      }
      getUsers();
    });
  };

  return (
    <Fragment>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "flex-end",
        }}
      >
        <Breadcrumb parent="Table" title="User Information" />
        <Button
          style={{
            width: "15%",
            height: "10%",
            marginTop: "2%",
            marginRight: "2%",
          }}
          onClick={() =>
            userStatus(userId?.status == "active" ? "blocked" : "active")
          }
        >
          {userId?.status == "active" ? "Block" : "Unblock"}
        </Button>

        <Button
          color="primary"
          style={{
            width: "17%",
            height: "10%",
            marginTop: "2%",
            marginRight: 5,
          }}
          onClick={() => handler()}
        >
          Export Listings
        </Button>
        {selectedListing?.length > 0 && (
          <Button
            color="primary"
            style={{
              marginLeft: 3,
              width: "13%",
              height: "10%",
              marginTop: "2%",
              marginRight: 2,
            }}
            onClick={() => deleteListing()}
          >
            Delete
          </Button>
        )}
        <Button
          style={{ width: "15%", height: "10%", marginTop: "2%" }}
          onClick={() => addListing()}
        >
          {"Add Listing"}
        </Button>
        <Button
          style={{
            marginLeft: 10,
            width: "15%",
            height: "10%",
            marginTop: "2%",
          }}
          onClick={() =>
            history(`${process.env.PUBLIC_URL}/app/address/${layout}`, {
              state: userId._id,
            })
          }
        >
          {"Add Address"}
        </Button>
      </div>
      <Container fluid={true}>
        <div style={{ width: "100%", height: "2%", padding: "1%" }}>
          <div style={{ fontWeight: "bold" }}>Name: {userId?.name}</div>
          <div style={{ fontWeight: "bold" }}>Email: {userId?.email}</div>
          <div style={{ fontWeight: "bold" }}>Mobile: {userId?.mobile}</div>
        </div>

        <Row>
          <Col sm="12">
            <Card>
              <CardBody>
                <DataTable
                  data={data}
                  columns={tableColumns}
                  center={true}
                  pagination
                  selectableRows
                  onSelectedRowsChange={handleRowSelected}
                  // clearSelectedRows={toggleCleared}
                />
              </CardBody>
            </Card>
          </Col>
        </Row>
        <h5 style={{ marginVertical: "10px" }}>Address</h5>
        <Row>
          {address.map((d) => {
            return (
              <Col sm={4}>
                <Card
                  style={{
                    padding: "10px",
                  }}
                >
                  <div
                    style={{ display: "flex", justifyContent: "space-between" }}
                  >
                    <div>
                      <h6 style={{ color: true ? "blue" : "black" }}>
                        {d._id}
                      </h6>

                      <h6 style={{ color: true ? "blue" : "black" }}>
                        {d.type}
                      </h6>

                      <h6 style={{ color: true ? "blue" : "black" }}>
                        {d.name}
                      </h6>
                      <p>{d.complete_address}</p>
                      <p>PIN Code : {d.pin_code}</p>
                      <p>{`${d.location?.coordinates[0]},  ${d.location?.coordinates[1]}`}</p>
                    </div>
                    <div>
                      <i
                        className="fa fa-trash"
                        style={{
                          cursor: "pointer",
                          width: 35,
                          fontSize: 16,
                          padding: 11,
                          color: "#e4566e",
                        }}
                        onClick={() => onRemoveAddress(d)}
                      ></i>
                    </div>
                  </div>
                </Card>
              </Col>
            );
          })}
        </Row>
      </Container>
    </Fragment>
  );
};

export default GetLisiting;
