import React, { Fragment, useState, useEffect } from "react";

const Loading = (props) => {
  const [show, setShow] = useState(true);
  const [data,setData] =useState(false)

  useEffect(() => {
    const timeout = setTimeout(() => {
      setShow(false);
      
    }, 3000);
    

    return () => {
      clearTimeout(timeout);
    };
  }, [show])

  return (
    <Fragment>
      <div
        // className={`loader-wrapper ${show ? "" : "loderhide"}`}
        className={`loader-wrapper `}
        style={{
          position: "relative",
          // position: "absolute"
          // marginTop: "10%",

          height: "80%",
          width: "98%",
          marginTop: "15%",
          borderRadius: "1em",
         
        }}
      >
       

        <div className="loader-index" style={{ position: "absolute" }}>
          <span></span>
        </div>

        <svg>
          <defs></defs>
          <filter id="goo">
            <fegaussianblur
              in="SourceGraphic"
              stdDeviation="11"
              result="blur"
            ></fegaussianblur>
            <fecolormatrix
              in="blur"
              values="1 0 0 0 0  0 1 0 0 0  0 0 1 0 0  0 0 0 19 -9"
              result="goo"
            >
              {" "}
            </fecolormatrix>
          </filter>
        </svg>
      </div>
    </Fragment>
  );
};

export default Loading;
