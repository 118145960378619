import React from 'react';
import { Card, CardBody } from 'reactstrap';

const UserDetail = (props) => {
    const user = props.user;

    return (
        <Card>
            <CardBody>
                <h5 style={{ color: 'blue' }}>User Details</h5>
                <div className='pan-info'>
                    <p className='pan-text pan-label'>Name</p> <h6 className='pan-text pan-value'>{user?.name ? user.name : "-"}</h6>
                </div>

                <div className='pan-info'>
                    <p className='pan-text pan-label'>Email</p> <h6 className='pan-text pan-value'>{user?.email}</h6>
                </div>
                <div className='pan-info'>
                    <p className='pan-text pan-label'>Mobile</p> <h6 className='pan-text pan-value'>{user?.mobile}</h6>
                </div>
            </CardBody>
        </Card>
    )
}

export default UserDetail;