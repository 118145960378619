import React, { useEffect, useState } from "react"
import Breadcrumb from "../../layout/breadcrumb";
import { Fragment } from "react";
import TextField from '@material-ui/core/TextField';
import adminServices from "../../api/adminService";
import { toast } from "react-toastify";




const Notify = () => {
    const [notify, setNotify] = useState([])


    const handleSubmit = async () => {
        const res = await adminServices.sendNotifications(JSON.parse(notify));
        if (res.status) {
            toast(res.message)
        } else {
            toast.error(res.message)
        }
    }

    return (

        <Fragment>
            <Breadcrumb title="Notification"
                buttonOptions={() => <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-end' }}>

                </div>}
            />
            <div>

                <textarea
                    value={notify}
                    style={{ borderRadius: "5px" }}
                    rows={25}
                    cols={150}
                    onChange={(e) => setNotify(e.target.value)}

                />
            </div>
            <button
                onClick={() => handleSubmit()}
                style={{
                    borderWidth: 1, border: 1, borderColor: "blue", backgroundColor: "blue",
                    transition: "0.5 ease", color: "white", padding: 10, width: "10%", borderRadius: 10, height: 40,
                    marginTop: "2%", cursor: "pointer", marginLeft: "20px"
                }}>
                <h6 style={{ color: "white", textAlign: "center" }}>Send Notification</h6>
            </button>
        </Fragment>

    )
}
export default Notify