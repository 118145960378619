import React, { Fragment, useState } from "react";
import Breadcrumb from '../../layout/breadcrumb'
import { Container, Row, Col, Card, CardBody, Form, FormGroup, Label, Input, Button, FormFeedback, CardFooter } from 'reactstrap'
import adminServices from "../../api/adminService";
import Select from 'react-select';
import { toast } from "react-toastify";
import { useLocation } from "react-router";
import { useNavigate } from "react-router";


const DocumentType = () => {

const history = useNavigate()

    const location = useLocation();
    const documentUpdate = location.state;
    console.log(documentUpdate);
const documentsId = documentUpdate?._id
console.log("documentsId", documentsId);
    const [formValues, setFormValues] = useState({
        name: documentUpdate?.name ? documentUpdate.name : "",
        key: documentUpdate?.key ? documentUpdate.key : "",
        type: documentUpdate?.type ? documentUpdate.type : "",
        description: documentUpdate?.description ? documentUpdate.description : ""
    })
    // const [backPage, setBackPage] = useState(documentUpdate?.backPage ? documentUpdate.backPage : "");
    const [checked, setChecked] = useState(false)
    const [isMandatory, setIsMandatory] = useState(true)


    const [formErrors, setFormErrors] = useState({
        name: "",
        key: "",
        type: "",
        description: ""
    })

    const handleOnChange = (e) => {
        setFormValues({ ...formValues, [e.target.name]: e.target.value });
        setFormErrors({ ...formErrors, [e.target.name]: "" });
    }


    const onSubmit = async () => {


        let errors = {};
        if (!formValues.name) {
            errors.name = "Please enter the Name";
        }
        if (!formValues.key) {
            errors.key = "Please enter the Key";
        }

        if (!formValues.type) {
            errors.type = "Please enter the type";
        }
        // if (!formValues.description) {
        //     errors.description = "Please enter the description";
        // }
        if (Object.keys(errors).length > 0) {
            setFormErrors(errors);
            return;
        }

        let res;

        if (documentUpdate) {
            res = await adminServices.updateDocument(documentsId,{
                name: formValues.name,
                key: formValues.key,
                type: formValues.type,
                backPage: checked,
                description: formValues.description,
                is_mandatory: isMandatory
            });
        } else {
            res = await adminServices.addDocument({
                name: formValues.name,
                key: formValues.key,
                type: formValues.type,
                backPage: checked,
                description: formValues.description,
                is_mandatory: isMandatory

            });
        }

        if (res.status) {
            toast(res.message)
        history(`${process.env.PUBLIC_URL}/app/documentlist/Dubai`);

        } else {
            toast.error(res.message)
        }
    }

    const onCheckedChange = (e) => {
        setChecked(!checked)
    }


    return (
        <Fragment>

            <Breadcrumb title="Document" />
            {/* <Container fluid={true} className="projectlist" > */}
            <Row>
                <Col sm="12">
                    <Card>
                        <CardBody>
                            <Form className="theme-form">
                                <Row>
                                    <Col sm="4">

                                        <FormGroup className='m-t-1'>
                                            <Label>{"Name"}</Label>
                                            <Input
                                                className="form-control"
                                                type="text"
                                                name="name"
                                                onChange={(e) => handleOnChange(e)}
                                                value={formValues.name}
                                                required
                                                placeholder={"Name"}
                                                valid={formErrors.name}
                                                invalid={formErrors.name}

                                            />
                                            <FormFeedback>
                                                {
                                                    formErrors.name
                                                }
                                            </FormFeedback>
                                        </FormGroup>
                                    </Col>

                                    <Col sm="4">
                                        <FormGroup className='m-t-1'>
                                            <Label>{"Key"}</Label>
                                            <Input className="form-control"
                                                type="text"
                                                name="key"
                                                placeholder={"Key"}
                                                onChange={(e) => handleOnChange(e)}
                                                // required="true"
                                                value={formValues.key}
                                                valid={formErrors.key}
                                                invalid={formErrors.key}

                                            />
                                            <FormFeedback>
                                                {
                                                    formErrors.key
                                                }
                                            </FormFeedback>
                                        </FormGroup>
                                    </Col>

                                    <Col sm="4">
                                        <FormGroup className='m-t-1'>
                                            <Label>{"Type"}</Label>
                                            <Input className="form-control"
                                                type="text"
                                                name="type"
                                                placeholder={"Type"}
                                                onChange={(e) => handleOnChange(e)}
                                                // required="true"
                                                value={formValues.type}
                                                valid={formErrors.type}
                                                invalid={formErrors.type}

                                            />
                                            <FormFeedback>
                                                {
                                                    formErrors.type
                                                }
                                            </FormFeedback>
                                        </FormGroup>
                                    </Col>
                                    <Col sm="12">
                                        <FormGroup className='m-t-1'>
                                            <Label>{"Description"}</Label>
                                            <Input className="form-control"
                                                type="textarea"
                                                name="description"
                                                rows="5"
                                                placeholder={"Description"}
                                                onChange={(e) => handleOnChange(e)}
                                                // required="true"
                                                value={formValues.description}
                                                valid={formErrors.description}
                                                invalid={formErrors.description}

                                            />

                                            <FormFeedback>
                                                {
                                                    formErrors.description
                                                }
                                            </FormFeedback>
                                        </FormGroup>
                                    </Col>
                                    <Col sm="2">
                                        <Label className="d-block" htmlFor="chk-ani" style={{ marginTop: 10 }} >

                                            <Input

                                                style={{ marginTop: 10 }}
                                                className="checkbox_animated checkbox_input"
                                                id="chk-ani" type="checkbox"
                                                value={checked}
                                                defaultChecked={checked}
                                                onChange={(e) => onCheckedChange(e)}
                                            />
                                            Backpage
                                        </Label>
                                    </Col>
                                    <Col sm="3">
                                        <Label className="d-block" htmlFor="chk-ani" style={{ marginTop: 10 }} >

                                            <Input

                                                style={{ marginTop: 10 }}
                                                className="checkbox_animated checkbox_input"
                                                id="chk-ani" type="checkbox"
                                                value={isMandatory}
                                                defaultChecked={isMandatory}
                                                onChange={(e) => setIsMandatory(!isMandatory)}
                                            />
                                            Mandatory
                                        </Label>
                                    </Col>





                                </Row>



                            </Form>
                        </CardBody>
                        <CardFooter>
                            <Button color="primary" className="me-3 "
                                onClick={() => onSubmit()}
                            >{"Save"}
                            </Button>
                        </CardFooter>
                    </Card>
                </Col>
            </Row>
            {/* </Container> */}
        </Fragment>
    )
}
export default DocumentType;





