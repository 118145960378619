// import React, { Fragment, useState ,useEffect} from 'react';
// import Breadcrumb from '../../layout/breadcrumb'

// import { Container, Row, Col, Card, CardBody, Form, FormGroup, Label, Input, Button, FormFeedback, CardFooter } from 'reactstrap'
// import { useForm } from 'react-hook-form'
// import { useDispatch } from 'react-redux';
// import adminServices from '../../api/adminService';
// import { toast } from 'react-toastify';
// import { useLocation } from 'react-router';
// import { useNavigate } from "react-router-dom";
// import Select from "react-select";

// const CreateRules = () =>{
//     const history =useNavigate()
//     const location=useLocation()
//     const rules = location?.state
//     console.log(rules);
//     const [formValues, setFormValues] = useState({
//       rule: rules?.rule ? rules?.rule : "",
//       value: rules?.value ? rules?.value : "",
//       defaultValue: rules?.defaultValue ? rules?.defaultValue : "",
//       // enum: rules?.enum ? rules?.enum : ""
//     });
//     const [formErrors, setFormErrors] = useState({
//       rule: "",
//       value: "",
//       defaultValue: "",
//       enum:[]
//     });
    
//     const [num,setEnum] =useState()

//     const dropdownHandler = (event) =>{
//       setEnum(event.value)
//     }
    
//     const handleOnChange = (event) => {
//       setFormValues({ ...formValues, [event.target.name]: event.target.value });
//       setFormErrors({ ...formErrors, [event.target.name]: "" });
//     };
     
//     const onSubmit = async () => {
//       let errors = {};
//       if (!formValues.rule) {
//         errors.rule = "Please enter the Rule";
//       }
//       if (!formValues.value) {
//         errors.value = "Please enter the Value";
//       }
//          if (!formValues.defaultValue) {
//            errors.defaultValue = "Please enter default value ";
//          }
//           if (!formValues.enum) {
//             errors.defaultValue = "Please enter default value ";
//           }

//       if (Object.keys(errors).length > 0) {
//         setFormErrors(errors);

//         return;
//       }

//       if (rules) {
//         const res = await adminServices.updateRule(rules?._id, {
//         ...formValues
//         });
//         console.log("ressss",res)
//         if (res.status) {
//           toast(res.message);
         
//           history(`${process.env.PUBLIC_URL}/app/rulesList/Dubai`, {
//             // state: res.data.type,
//           });
//         } else {
//           toast.error(res.message);
//         }
//       } else {
//         const res = await adminServices.createRule(formValues);
//         console.log(res);
//         if (res.status) {
//           toast(res.message);
         
//         history(`${process.env.PUBLIC_URL}/app/rulesList/Dubai`, {
         
//         });
//         } else {
//           toast.error(res.message);
//         }
//       }
//      };
//   return (
//     <Fragment>
//       <Breadcrumb title="Create Rules" />
//       {/* <Container fluid={true} className="projectlist" > */}
//       <Row>
//         <Col sm="12">
//           <Card>
//             <CardBody>
//               <Form className="theme-form">
//                 <Row>
//                   <Col sm="4">
//                     <FormGroup className="m-t-1">
//                       <Label>{"Rule"}</Label>
//                       <Input
//                         className="form-control"
//                         type="text"
//                         name="rule"
//                         value={formValues.rule}
//                         defaultValue=""
//                         valid={formErrors.rule}
//                         invalid={formErrors.rule}
//                         onChange={(e) => handleOnChange(e)}
//                         required
//                         placeholder={"Rule"}
//                       />
//                       <FormFeedback>{formErrors.rule}</FormFeedback>
//                       {/*  <FormFeedback>{formErrors.rule}</FormFeedback>*/}
//                     </FormGroup>
//                   </Col>
//                   <Col sm="4">
//                     <FormGroup className="m-t-1">
//                       <Label>{"Value"}</Label>
//                       <Input
//                         className="form-control"
//                         type="text"
//                         name="value"
//                         value={formValues.value}
//                         defaultValue=""
//                         valid={formErrors.value}
//                         invalid={formErrors.value}
//                         onChange={(e) => handleOnChange(e)}
//                         required
//                         placeholder={"Value"}
//                       />
//                       <FormFeedback>{formErrors.value}</FormFeedback>
//                     </FormGroup>
//                   </Col>
//                   <Col sm="4">
//                     <FormGroup className="m-t-1">
//                       <Label>{"DefaultValue"}</Label>
//                       <Input
//                         className="form-control"
//                         type="text"
//                         name="defaultValue"
//                         value={formValues.defaultValue}
//                         defaultValue=""
//                         valid={formErrors.defaultValue}
//                         invalid={formErrors.defaultValue}
//                         onChange={(e) => handleOnChange(e)}
//                         required
//                         placeholder={"Name"}
//                       />
//                       <FormFeedback>{formErrors.defaultValue}</FormFeedback>
//                     </FormGroup>
//                   </Col>
//                   <Col sm="4">
//                     <FormGroup className="m-t-1">
//                       <Label>{"enum"}</Label>
//                       <Input
//                         className="form-control"
//                         type="text"
//                         name="enum"
//                         value={formValues.enum}
//                         defaultValue=""
//                         valid={formErrors.enum}
//                         invalid={formErrors.enum}
//                         onChange={(e) => handleOnChange(e)}
//                         required
//                         placeholder={"enum"}
//                       />
//                       <FormFeedback>{formErrors.enum}</FormFeedback>
//                     </FormGroup>
//                   </Col>
//                 </Row>

//                 {/* <Row>
//                     <Col>
//                       <FormGroup>
//                         <Button color="primary" className="me-3 m-t-1" onClick={() => onSubmit()}>{Save}</Button>

//                       </FormGroup>
//                     </Col>
//                   </Row> */}
//               </Form>
//             </CardBody>
//             <CardFooter>
//               <Button
//                 color="primary"
//                 className="me-3 "
//                 onClick={() => onSubmit()}
//               >
//                 {"Save"}
//               </Button>
//             </CardFooter>
//           </Card>
//         </Col>
//       </Row>
//       {/* </Container> */}
//     </Fragment>
//   );
// }
// export default CreateRules
import React from 'react'

export default function CreateRules() {
  return (
    <div>CreateRules</div>
  )
}



