import requests from "./httpservices";

const adminServices = {
  userCreation(body) {
    console.log(body);
    return requests.post(`admin/user`, body);
  },
  updateUser(id, body) {
    return requests.post(`admin/user/${id}`, body);
  },
  getUsers(type) {
    return requests.get(`admin/users?${type}`);
  },
  deleteUser(id) {
    return requests.delete(`admin/user/${id}`);
  },
  addCategory(body) {
    return requests.post(`admin/category`, body);
  },
  updateCategory(body, id) {
    console.log(body);
    return requests.post(`admin/category/${id}`, body);
  },
  getCategory() {
    return requests.get(`common/category`);
  },
  categoryDelete(id) {
    return requests.delete(`admin/category/${id}`);
  },
  getBookings(query) {
    return requests.get(`admin/bookings?${query}`);
  },
  getReservations() {
    return requests.get(`admin/reservations`);
  },
  addEquipment(id, body) {
    return requests.post(`admin/equipment/${id}`, body);
  },
  updateEquipment(id, body) {
    return requests.post(`admin/equipments/${id}`, body);
  },
  getListing(id) {
    return requests.get(`admin/listing/${id}`);
  },
  addListing(body, id) {
    return requests.post(`admin/listing/${id}`, body);
  },
  updateListing(id, body) {
    return requests.post(`admin/update-listing/${id}`, body);
  },
  dropDown(body, type) {
    return requests.post(`admin/dropdown/${type}`, body);
  },
  updateDropDown(id, body) {
    return requests.put(`admin/dropdown/${id}`, body);
  },
  getDropdowns() {
    return requests.get(`admin/dropdown`);
  },
  deleteDropDown(id) {
    return requests.delete(`admin/dropdown/${id}`);
  },
  getAddress(id) {
    return requests.get(`admin/address/${id}`);
  },
  addAddress(id, body) {
    console.log(id, body);
    return requests.post(`admin/address/${id}`, body);
  },
  addDocument(body) {
    return requests.post(`admin/document-type`, body);
  },
  getList(qry) {
    return requests.get(`admin/list?${qry}`);
  },
  getDocuments() {
    return requests.get(`admin/documents`);
  },
  updateDocument(id, body) {
    return requests.post(`admin/update-doument/${id}`, body);
  },
  deleteDocument(id) {
    return requests.delete(`admin/document/${id}`);
  },
  getEnquiries(id) {
    return requests.get(`admin/enquiries`);
  },
  updateListStatus(id, body) {
    return requests.post(`admin/listing-status/${id}`, body);
  },
  getBookingEnquiries(id) {
    return requests.get(`admin/booking-enquiries/${id}`);
  },
  updateEnqStatus(id, body) {
    return requests.post(`admin/enq-status/${id}`, body);
  },
  getEnquriesForQuotation(id) {
    return requests.get(`admin/quote-enq/${id}`);
  },
  saveQuotation(id, body) {
    return requests.post(`admin/quotation/${id}`, body);
  },
  getQuotations() {
    return requests.get(`admin/quotations`);
  },
  getEquipments(id) {
    return requests.get(`admin/equipments/${id}`);
  },
  getRole() {
    return requests.get(`common/roles`);
  },
  createRule(body) {
    return requests.post(`admin/rules`, body);
  },
  updateRule(id, body) {
    return requests.post(`admin/rules/${id}`, body);
  },
  getRules() {
    return requests.get(`common/rules`);
  },
  getDahboardData() {
    return requests.get(`admin/dashboard`);
  },
  updateUserStatus(id, body) {
    return requests.post(`admin/user-status/${id}`, body);
  },
  excelListing(qry) {
    return requests.get(`admin/export/listing`, { responseType: "blob" });
  },
  exportUsers(qry) {
    return requests.get(`admin/export/users?${qry}`, { responseType: "blob" });
  },
  deleteMultipleListing(body) {
    return requests.post(`admin/listig-delete/`, body);
  },
  getOtpByPassData() {
    return requests.get(`admin/bypass`);
  },
  addOtpByPassData(body) {
    console.log(body);
    return requests.post(`admin/bypass`, body);
  },
  removeOtpByPassData(id) {
    console.log(id);
    return requests.delete(`admin/bypass/${id}`);
  },
  getTheme() {
    return requests.get(`auth/theme`);
  },
  editTheme(body) {
    console.log("body", body);
    return requests.post(`admin/theme`, body);
  },
  editTheme(body) {
    console.log(body);
    return requests.post(`admin/theme`, body);
  },

  sendNotifications(body) {
    return requests.post(`admin/notify`, body);
  },
  sendNotification(type, user_id, body) {
    console.log("user_id,body", type, user_id, body);
    return requests.post(`admin/notify-user/${type}/${user_id}`, body);
  },
  deleteRule(id) {
    return requests.delete(`admin/rules/${id}`);
  },
  inCompleteProfile() {
    return requests.get(`admin/incomplete-profiles`);
  },

  UpdateIncompleteProfile(id, body) {
    console.log(id, body);
    return requests.post(`admin/incomplete-profiles${id}`, body);
  },
  uploadListings(body) {
    return requests.post(`admin/upload-listings`, body);
  },
  deleteAddress(id) {
    return requests.delete(`common/address/${id}`);
  },
  logoutUser() {
    return requests.get(`admin/logout`);
  },
};

export default adminServices;
