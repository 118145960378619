import React, { useState, useEffect } from "react";
import { Fragment } from "react";
import Breadcrumb from "../../layout/breadcrumb";
import { Container } from "react-bootstrap";
import { Card, CardBody, Col, Row, Button, CardHeader } from "reactstrap";
import { useLocation } from "react-router";
import adminServices from "../../api/adminService";
import { toast } from "react-toastify";
import { API_BASE_URL } from "../../api/url";
import SweetAlert from 'sweetalert2'
import { useNavigate } from "react-router";





const UpdateListingStatus = () => {
  const history = useNavigate();


  const location = useLocation();
  const data = location.state;
  const userId = data?.user_id?._id

  const [loading, setLoading] = useState(false);
  const [update, setUpdate] = useState(data)
  const [userAddress, setUserAddress] = useState([]);

  const onUpdateStatus = async (status) => {
    // setLoading(true);

    SweetAlert.fire({
      title: "Are you sure?",
      text: "Once deleted, you will not be able to recover this user!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Ok",
      cancelButtonText: "cancel",
      reverseButtons: true,
  }).then((result) => {

      if (result.value) {
        adminServices.updateListStatus(update._id, {
          status: status,
        }).then(res => {
          console.log("res",res)
          setLoading(false);
          if (res.status) {
            setUpdate(res.data)
            toast(res.message)
          } else {
            toast.error(res.message)
          }
        })

      } else {
          SweetAlert.fire("Your file is safe!");
      }
  });
    
  
      }


  const onApproveStatus = async (status) => {
    // setLoading(true);

    SweetAlert.fire({
      title: "Are you sure?",
      text: "Do you want to Approve the listing",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Ok",
      cancelButtonText: "cancel",
      reverseButtons: true,
  }).then((result) => {

      if (result.value) {
        adminServices.updateListStatus(update._id, {
          status: status,
        }).then(res => {
          console.log("res",res)
          setLoading(false);
          if (res.status) {
            setUpdate(res.data)
            toast(res.message)
          } else {
            toast.error(res.message)
          }
        })

      } else {
          SweetAlert.fire("Your file is safe!");
      }
  });
    
        // const res = await adminServices.updateListStatus(update._id, {
        //   status: status,
        // });

        // setLoading(false);
        // if (res.status) {
        //   setUpdate(res.data)
        //   toast(res.message)
        // } else {
        //   toast.error(res.message)
        // }
      }
    
      const onRemoveListing = (id) => {
        SweetAlert.fire({
          title: "Are you sure?",
          text: "Once deleted, you will not be able to recover this user!",
          icon: "warning",
          showCancelButton: true,
          confirmButtonText: "Ok",
          cancelButtonText: "cancel",
          reverseButtons: true,
        }).then((result) => {
    
          if (result.value) {
            const res = adminServices.updateListing(id, { isDeleted: true });
            history(`${process.env.PUBLIC_URL}/app/list/Dubai`);

            if (res.status) {
              setUpdate(res.data)
              toast(res.message)

              SweetAlert.fire("Spam!", "Your User has been spamed.", "success");

            }
    
          } else {
            SweetAlert.fire("Your file is safe!");
          }
        });
      };
    


  
  useEffect(() => {
    async function getAddress() {
      const res = await adminServices.getAddress(userId);
      if (res.status) {
        setUserAddress(res.data);
      }
    };

    getAddress();

  }, [0])




  const getUserAddress = userAddress?.map(d => { return (d) })
  console.log("ggg", getUserAddress)
  const getId = getUserAddress.find(i => i._id === update?.address)

  console.log("getId", getId)
  // .find(a => a?._id=== update?._id)
  console.log("getUserAddress", getUserAddress)
  console.log("useraddd", userAddress)
  console.log("update", update)

  return (
    <Fragment>
      <Breadcrumb parent="Table" title="Listing Status" renderOptions={() => {
        return (
          <div style={{ width: "100%", height: "2%", padding: "0%" }}>


            <div style={{ fontWeight: "bold" }}>
              Name: {data?.user_id?.name}
            </div>
            <div style={{ fontWeight: "bold" }}>

              Email:  {data?.user_id?.email}
            </div>
            <div style={{ fontWeight: "bold" }}>
              Organization Name:  {data?.user_id?.organization_name}
            </div>
            <div style={{ fontWeight: "bold" }}>

              Mobile: {data?.user_id?.mobile}
            </div>

          </div>
        );
      }} />

      <Container fluid={true}>
        <Row>
          <Col sm="12">
            <Card>
              {/* <CardHeader>
                                <Button style={{ margin: 10 }}>Approved</Button>
                                <Button style={{ margin: 10 }}>Rejected</Button>
                            </CardHeader> */}
              <CardBody>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "flex-end",
                    marginLeft: "15%",
                    minHeight: 30,
                  }}
                >
                 { update.status !== "Spam" ?<Button
                    style={{ margin: 10 }}
                    onClick={() => onUpdateStatus("Spam")}
                  >
                    Spam
                  </Button> : ""}
                  <Button
                    style={{ margin: 10 }}
                    onClick={() => onRemoveListing(data._id)}
                    >
                    Delete
                  </Button>
                  {
                    update.status == "Submitted" && (
                      <Button
                        style={{ margin: 10 }}
                        onClick={() => onApproveStatus("Approved")}
                      >
                        Approve
                      </Button>
                    )
                  }

                  {update.status == "Submitted" && (
                    <Button
                      style={{ margin: 10 }}
                      onClick={() => onUpdateStatus("Rejected")}
                    >
                      Reject
                    </Button>)}
                </div>

                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    width: "100%",
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      width: "50%",
                      justifyContent: "space-between",
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "space-between",
                      }}
                    >
                      <p style={{ fontSize: 15, color: "#757575" }}>Brand</p>
                      <h6 style={{ fontSize: 15 }}>{update.brand}</h6>
                    </div>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "space-between",
                      }}
                    >
                      <p style={{ fontSize: 15, color: "#757575" }}>Model</p>
                      <h6 style={{ fontSize: 15 }}>{update.model}</h6>
                    </div>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "space-between",
                      }}
                    >
                      <p style={{ fontSize: 15, color: "#757575" }}>
                        Manufacturing Year
                      </p>
                      <h6 style={{ fontSize: 15 }}>{update.mrg_year}</h6>
                    </div>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "space-between",
                      }}
                    >
                      <p style={{ fontSize: 15, color: "#757575" }}>Status</p>
                      <h6 style={{ fontSize: 15 }}>{update.status}</h6>
                    </div>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "space-between",
                      }}
                    >
                      <p style={{ fontSize: 15, color: "#757575" }}>Stage</p>
                      <h6 style={{ fontSize: 15 }}>{update.stage}</h6>
                    </div>
                    {/* <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "space-between",
                      }}
                    >
                      <p style={{ fontSize: 15, color: "#757575" }}>
                        Usage Hours
                      </p>
                      <h6 style={{ fontSize: 15 }}>{update.usage_hours}</h6>
                    </div> */}
                    {/* <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "space-between",
                      }}
                    >
                      <p style={{ fontSize: 15, color: "#757575" }}>
                        Usage Run
                      </p>
                      <h6 style={{ fontSize: 15 }}>{update.usage_run}</h6>
                    </div> */}

                    {/* <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "space-between",
                      }}
                    >
                      <p style={{ fontSize: 15, color: "#757575" }}>
                        Weekly Expected Rent
                      </p>
                      <h6 style={{ fontSize: 15 }}>{update?.expected_rent?.weekly}</h6>
                    </div> */}

                        <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "space-between",
                      }}
                    >
                      <p style={{ fontSize: 15, color: "#757575" }}>
                       Capacity
                      </p>
                      <h6 style={{ fontSize: 15 }}>{update?.capacity}</h6>
                    </div> 

                  </div>
                </div>
              </CardBody>
            </Card>
            {getId ? <Row>
              <Col sm={4}>
                <Card style={{
                  padding: "10px",

                }}>
                  <h6 >{getId?.name}</h6>
                  <p >{getId?.complete_address}</p>
                  <p >{getId?.pin_code}</p>
                </Card>

              </Col>
            </Row> : <Row>
              <Col sm={4}>
                <Card style={{
                  padding: "10px",

                }}>
                  <h6 >No address found</h6>

                </Card>

              </Col>
            </Row>}
            {update.attachments.length ? (
              <Card>
                <CardBody>
                  {update && update?.attachments?.map((images) => {
                    return (
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          justifyContent: "space-between",
                          margin: "2%",
                        }}
                      >
                        <h6 style={{ fontSize: 15 }}>{images.doc_type.name}</h6>

                        <div>
                          {
                            images.uploads?.map((upload) => {
                              return <div> <p>{upload.path}</p> <Button color="primary" onClick={() => window.open(API_BASE_URL + "" + upload.path, '_blank')}>
                                View
                              </Button> </div>
                            })
                          }
                        </div>

                      </div>
                    );
                  })}
                </CardBody>
              </Card>
            ) : (
              <Card style={{ height: "10%" }}>
                <div style={{ alignSelf: "center" }}>
                  <h6 style={{ fontSize: 20, marginTop: "8%" }}>No Images found</h6>
                </div>
              </Card>
            )}
          </Col>
        </Row>
      </Container>
    </Fragment>
  );
}
export default UpdateListingStatus;