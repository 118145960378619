import requests from "./httpservices";

const authServices = {



    webLogin(body) {

        return requests.post(`auth/login`, body)
    }

}

export default authServices;