import React, { Fragment, useEffect, useState } from "react";
import { useLocation } from "react-router";
import { Container, Row, Col, Card, CardBody, CardHeader, Button, Modal, ModalHeader, ModalBody, ModalFooter, Input } from "reactstrap";
import Breadcrumb from "../../layout/breadcrumb";
import moment from "moment/moment";
import UserDetail from "./UserDetails";
import JobSiteDetails from "./JobSiteDetails";
import adminServices from "../../api/adminService";
import DataTable from "react-data-table-component";


const BookingDetails = () => {

    const location = useLocation();

    const [loading, setLoading] = useState(true);
    const [data, setData] = useState([]);

    const [showEnquries, setShowEnquries] = useState(false);
    const [showQuotation, setShowQuotation] = useState(false);

    const [acceptedEnq, setAcceptedEnq] = useState([]);



    const booking = location.state;

    const ModalToggle = () => setShowQuotation(!showQuotation);

    const [quotation, setQuotation] = useState({});



    useEffect(() => {
        async function getBookingEnquiries() {

            const res = await adminServices.getBookingEnquiries(
                location.state._id
            );
            setLoading(false);
            if (res.status) {
                setData(res.data)
            }
            else {
                setData([]);
            }


            const rest = await adminServices.getQuotations();

        }
        getBookingEnquiries()

    }, [0])

    const showQuoteOptions = async () => {
        const res = await adminServices.getEnquriesForQuotation(booking._id);

        setShowQuotation(true);
        setAcceptedEnq(res.data);
    }


    const udpateEnqStatus = async (id, status) => {
        const res = await adminServices.updateEnqStatus(id, { status: status });

        if (res.status) {

        } else {

        }
    }

    const saveQuotation = async () => {


        let ids = data.map((d) => d._id);

        const enqids = { ...quotation, enquries: ids, user_id: booking.user_id };
        const res = await adminServices.saveQuotation(booking._id, enqids);
        if (res.status) {
            ModalToggle();
        } else {
            console.log("XJKK", res);
        }
    }

    const onChangeText = (event, key) => {
        setQuotation(data => {
            return { ...data, [key]: event.target.value };
        })
    }


    const tableColumns = [
        {
            name: "ID",
            selector: row => row.uid
        },
        {
            name: 'Brand',
            selector: row => row.listing_id?.brand,
            sortable: true
        },
        {
            name: 'Status',
            selector: row => row.status,
            center: true,
        },
        {
            name: 'Quantity',
            selector: row => row.quantity,
            center: true,
        },
        {
            name: 'Admin Status',
            selector: row => row.confirm_status,
            center: true,
        }];

    return (

        <Fragment>
            <Breadcrumb parent="Table" title="Bookings" renderOptions={() => {
                return (
                  <div style={{ display: "flex", flexDirection: "row" }}>
                  
                    <Button
                     style={{ marginRight: "2%", width: "70%",height:"2%", padding:"3%" }}
                      onClick={() => showQuoteOptions()}
                    >
                      Generate Quotation
                    </Button>
                    <Button
                     style={{ marginRight: "2%", width: "60%",padding:"3%"}}
                      onClick={() => setShowEnquries(!showEnquries)}
                    >
                      View Enquiries
                    </Button>
                  </div>
                );
            }} />
            <Container fluid={true}>
                <Row>
                    {
                        showEnquries && <Col sm="12">
                            <Card>
                                <CardBody>
                                    <h5 style={{ color: 'blue' }}>Enquiries</h5>
                                    {
                                        data.map((d) => {
                                            let user = d.supplier_id;
                                            return <div>
                                                {
                                                    d.status == 'Accepted' && <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                                                        <Button onClick={() => udpateEnqStatus(d._id, "Accepted")} color="primary" style={{ marginRight: '10px' }}>Accept </Button>
                                                        <Button onClick={() => udpateEnqStatus(d._id, "Rejected")} color="secondary">Reject </Button>
                                                    </div>
                                                }
                                                <Row>
                                                    <Col sm="6">

                                                        <div className='pan-info'>
                                                            <p className='pan-text pan-label'>ENQ Id</p> <h6 className='pan-text pan-value'>{d.uid}</h6>
                                                        </div>
                                                        <div className='pan-info'>
                                                            <p className='pan-text pan-label'>Status</p> <h6 className='pan-text pan-value'>{d.status}</h6>
                                                        </div>
                                                        <div className='pan-info'>
                                                            <p className='pan-text pan-label'>Amount</p> <h6 className='pan-text pan-value'>{d.quoted_amount}</h6>
                                                        </div>
                                                        <div className='pan-info'>
                                                            <p className='pan-text pan-label'>Quantity</p> <h6 className='pan-text pan-value'>{d.quantity}</h6>
                                                        </div>

                                                        <div className='pan-info'>
                                                            <p className='pan-text pan-label'>Admin Status</p> <h6 className='pan-text pan-value'>{d.confirm_status}</h6>
                                                        </div>
                                                    </Col>
                                                    <Col sm="6">
                                                        <h6>Supplier Details</h6>
                                                        <div className='pan-info'>
                                                            <p className='pan-text pan-label'>Name</p> <h6 className='pan-text pan-value'>{user?.name ? user.name : "-"}</h6>
                                                        </div>

                                                        <div className='pan-info'>
                                                            <p className='pan-text pan-label'>Email</p> <h6 className='pan-text pan-value'>{user?.email}</h6>
                                                        </div>
                                                        <div className='pan-info'>
                                                            <p className='pan-text pan-label'>Mobile</p> <h6 className='pan-text pan-value'>{user?.mobile}</h6>
                                                        </div>
                                                    </Col>

                                                </Row>
                                                <hr />
                                            </div>
                                        })
                                    }

                                </CardBody>
                            </Card>

                        </Col>

                    }
                    <Col sm="6">
                        <Card>
                            {
                                location.state && <CardBody  >
                                    <h5 style={{ color: 'blue' }}>Booking Details</h5>

                                    <div style={{ display: "flex", flexDirection: "column", width: "100%", }}>

                                       { /*<div className='pan-info'>
                                            <p className='pan-text pan-label'>Booking Id</p> <h6 className='pan-text pan-value'>{booking.uid}</h6>
                            </div>*/}
                            <div style={{ display: "flex", flexDirection: "row", padding: 5, justifyContent: 'space-between' }}>
                                            <p className='pan-text pan-label'> Booking Id</p>     <h6 className='pan-text pan-value' >{booking.uid}</h6>
                                        </div>
{/*
                                        <div style={{ display: "flex", flexDirection: "row", padding: 5, justifyContent: 'space-between' }}>

                                            <p className='pan-text pan-label'>Equipment</p>
                                            <h6 style={{ color: 'blue' }}>{location.state.equipment_id.title}</h6>
                            </div>*/}
                                         <div style={{ display: "flex", flexDirection: "row", padding: 5, justifyContent: 'space-between' }}>
                                            <p className='pan-text pan-label'> Equipment</p>     <h6 className='pan-text pan-value'>{location.state.equipment_id.title}</h6>
                                        </div>


                                        <div style={{ display: "flex", flexDirection: "row", padding: 5, justifyContent: 'space-between' }}>
                                            <p className='pan-text pan-label'> Quantity</p>     <h6 className='pan-text pan-value'>{location.state.quantity}</h6>
                                        </div>

                                        <div style={{ display: "flex", flexDirection: "row", padding: 5, justifyContent: 'space-between' }}>
                                            <p className='pan-text pan-label'> Shift</p>     <h6 className='pan-text pan-value'>{location.state.shift}</h6>
                                        </div>
                                        <div style={{ display: "flex", flexDirection: "row", padding: 5, justifyContent: 'space-between' }}>
                                            <p className='pan-text pan-label'> Specifications</p>   <h6 className='pan-text pan-value'>{location.state.specifications}</h6>
                                        </div>
                                        <div style={{ display: "flex", flexDirection: "row", padding: 5, justifyContent: 'space-between' }}>
                                            <p className='pan-text pan-label'> Status</p>     <h6 className='pan-text pan-value'>{location.state.status}</h6>
                                        </div>
                                        <div style={{ display: "flex", flexDirection: "row", padding: 5, justifyContent: 'space-between' }}>
                                            <p className='pan-text pan-label'> Rental Period</p>
                                            <h6 className='pan-text pan-value'>{`${moment(location.state.rental_period[0]).format("DD-MM-YYYY")} 
                                             to ${moment(location.state.rental_period[1]).format("DD-MM-YYYY")}`}</h6>
                                        </div>


                                    </div>



                                </CardBody>

                            }
                        </Card>
                    </Col>

                    <Col sm="6">
                        <UserDetail user={location.state.user_id} />
                    </Col>

                    <Col sm="6">
                    <Card>
            <CardBody>
                <h5 style={{ color: 'blue' }}>Address</h5>
                <div className='pan-info'>
                    <p className='pan-text pan-label'>City</p> <h6 className='pan-text pan-value'>{booking?.address?.city ? booking?.address?.city : "-"}</h6>
                </div>
                <div className='pan-info'>
                    <p className='pan-text pan-label'>state</p> <h6 className='pan-text pan-value'>{booking?.address?.state}</h6>
                </div>
                <div className='pan-info'>
                    <p className='pan-text pan-label'>Country</p> <h6 className='pan-text pan-value'>{booking?.address?.country}</h6>
                </div> 
                <div className='pan-info'>
                    <p className='pan-text pan-label'>Pincode</p> <h6 className='pan-text pan-value'>{booking?.address?.pin_code}</h6>
                </div>
                <div className='pan-info' style={{display:"flex",justifyContent:"space-between"}}>
                    <p className='pan-text pan-label'>Complete Address : </p> <h6 className='pan-text pan-value'>{booking?.address?.complete_address}</h6>
                </div>
            </CardBody>
        </Card>

                    </Col>

                    <Col sm="6">
                        {/* <JobSiteDetails jobsite={location.state.jobsite_id} /> */}
                    </Col>

                    <Modal isOpen={showQuotation} toggle={ModalToggle}>
                        <ModalHeader toggle={ModalToggle} >
                            {"New Quotation"}
                        </ModalHeader>
                        <ModalBody>

                            <Container fluid={true} className="bd-example-row">
                                <Row>
                                    <Col md="4">
                                        Quote Amount
                                    </Col>
                                    <Col md="6">
                                        <Input onChange={(event) => onChangeText(event, "amount")} value={quotation.amount} placeholder="Amount" />
                                    </Col>
                                </Row>

                                <Row className="mt-3">
                                    <Col md="4">
                                        Fake Price
                                    </Col>
                                    <Col md="6">
                                        <Input onChange={(event) => onChangeText(event, "fake_price")} value={quotation.fake_price} placeholder="Fake price" />
                                    </Col>
                                </Row>
                                <Row className="mt-3">
                                    <Col md="4">
                                        Quantity
                                    </Col>
                                    <Col md="6">
                                        <Input onChange={(event) => onChangeText(event, "quantity")} value={quotation.quantity} placeholder="Quantity" />
                                    </Col>
                                </Row>
                                <Row className="mt-3">
                                    <Col md="12">
                                        Terms
                                    </Col>
                                    <Col md="12" className="ms-auto">
                                        <Input onChange={(event) => onChangeText(event, "terms")} value={quotation.terms} type="textarea" placeholder="Terms" />
                                    </Col>
                                </Row>

                                <Row className="mt-3">
                                    <Col md="12">
                                        Remarks
                                    </Col>
                                    <Col md="12" className="ms-auto">
                                        <Input onChange={(event) => onChangeText(event, "remarks")} value={quotation.remarks} type="textarea" placeholder="Remarks" />
                                    </Col>
                                </Row>
                            </Container>
                        </ModalBody>
                        <ModalFooter>
                            <Button color="secondary" onClick={ModalToggle}>{"Close"}</Button>
                            <Button color="primary" onClick={() => saveQuotation()}>{"SaveChanges"}</Button>
                        </ModalFooter>
                    </Modal>

                    {/* <Col sm="6">
                        <h6>Enquiries</h6>
                        <DataTable
                            data={data}
                            columns={tableColumns}
                            center={true}
                        />
                    </Col> */}
                </Row>
            </Container>
        </Fragment>

    )
}
export default BookingDetails;