import React, { useState } from "react";
import { useEffect } from "react";
import adminServices from "../../api/adminService";

import { useNavigate } from "react-router";
import Breadcrumb from "../../layout/breadcrumb";
import { classes } from '../../data/layouts'
import { Fragment } from "react";
import { toast } from "react-toastify";
import TextField from '@material-ui/core/TextField';
import {
  Row, Col, Card, CardBody, CardHeader, Button, Modal, ModalBody, ModalFooter, Form,
  ModalHeader, Label, Input, FormGroup, FormFeedback
} from "reactstrap";



const EditTheme = () => {
  const [data, setData] = useState("")
  const [loader, setLoader] = useState(false)

  const history = useNavigate();
  const defaultLayoutObj = classes.find(item => Object.values(item).pop(1) === 'compact-wrapper');
  const layout = localStorage.getItem('layout') || Object.keys(defaultLayoutObj).pop();

  const [status, setStatus] = useState('all');
  const [number, setNumber] = useState("")
  const [font, setFont] = useState("")
  const [VaryingContentone, setVaryingContentone] = useState(false);
  const [addColor, setAddColor] = useState(false)
  const [addFont, setAddFont] = useState()
  const [openAddFont, setOpenAddFont] = useState(false)
  const [editFont, setEditFont] = useState(false)
  const [open, setOpen] = useState(false)


  // const [colorskey, setColorskey] = useState({
  //   key: "",
  //   value: ""

  // })

  // const { key, value } = colorskey
  const [key, setKey] = useState(null)
  const [color, setColor] = useState(null)
  // console.log("hh", Object.entries(data))



  const [themeData, setThemeData] = useState(

  );
  // console.log("ss", themeData)

  const handleColorChange = (key, value) => {
    setThemeData((prevData) => ({
      ...prevData,
      colors: {
        ...prevData.colors,
        [key]: value,
      },
    }));
  };

  const handleFontChange = (platform, key, value) => {
    setThemeData((prevData) => ({
      ...prevData,
      fonts: {
        ...prevData.fonts,
        [platform]: {
          ...prevData.fonts[platform],
          [key]: value,
        },
      },
    }));
  };

  const handleSubmit = async () => {
    if (themeData) {

      const res = await adminServices.editTheme({ theme: themeData })
      if (res) {
        
        toast(res.message)
        setKey("")
        setColor("")

        setAddColor(false)
        setVaryingContentone(false)
        setOpenAddFont(false)
        setEditFont(false)



      }
      // console.log("res", res)
    }


    // console.log('Updated theme data:', themeData);
  };



  //   }

  async function getTheme() {

    setLoader(true)
    const res = await adminServices.getTheme();
    // console.log(res)
    if (res.status) {
      setLoader(false)
      setThemeData(res.data)
    } else {
      setThemeData("");
    }
  }
  useEffect(() => {
    getTheme();

  }, [])

  // console.log("theme", themeData)



  // console.log(key, color)
  const handleColor = async () => {

    if (key && color) {
      const res = await adminServices.editTheme({ theme: themeData })
      console.log("res", res)
      if (res.status) {
        getTheme()
        setAddColor(!addColor);
        toast(res.message)

      }
    }
  }

  useEffect(() => {
    if (key && color) {
      setThemeData((prevData) => ({
        ...prevData,
        colors: {
          ...prevData.colors,
          [key]: color,
        },
      }));

    }


  }, [key, color])


  const fontHandler =async () => {
    if (font && number) {

      const res = await adminServices.editTheme({ theme: themeData })
      if (res) {
        getTheme()
        setAddColor(!addColor);
        toast(res.message)



      }
      // console.log("res", res)
    }
  
  }



  useEffect(()=>{
    if(font && number){
      
      setThemeData((prevData) => ({
        ...prevData,
        fonts: {
          ...prevData.fonts,
          android: {
            ...prevData.fonts["android"],
            [font]: number,
          },
          
          ios: {
          ...prevData.fonts["ios"],
          [font]: number,
        },
      },
    }));
  }



  },[font,number])
console.log("tt",themeData)

  const displayModel = (row) => {
    if (row) {
      setThemeData(row);
      setVaryingContentone(!VaryingContentone);
    } else {
      setVaryingContentone(!VaryingContentone);
    }
  }
  // console.log("lll", themeData)



  const addColorDisplayModel = (row) => {
    if (row) {
      setThemeData(row);
      setAddColor(!addColor);
    } else {
      setAddColor(!addColor);

    }
  }







  const addFontDisplayModel = (row) => {
    if (row) {
      setThemeData(row);

      setOpenAddFont(!openAddFont);
    } else {
      setOpenAddFont(!openAddFont);

    }
  }


  const editFontdisplayModel = (row) => {
    if (row) {
      setThemeData(row);

      setEditFont(!editFont);
    } else {
      setEditFont(!editFont);
    }
  }
  return (
    <Fragment>
      <Breadcrumb parent="Table" title="Edit Theme"
        buttonOptions={() => <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-end' }}>

        </div>}
      />
      <div>

        <Card>

          <div style={{ padding: "20px" }}>
            <div style={{ display: "flex", justifyContent: "space-between" }}>

              <div>

                <h3>Colors:</h3>
              </div>
              <div style={{}}>


                <Button style={{}} color="primary"
                  onClick={() =>
                    // addDropdown()
                    addColorDisplayModel()
                  }
                >{"Add"}</Button>

              </div>
            </div>

            {themeData && Object.entries(themeData?.colors)?.map(([key, value]) => (
              <div>

                <div key={key} style={{ display: 'flex', justifyContent: 'space-between', margin: "6px" }}>
                  <span style={{ marginLeft: "20px" }}>{key}: </span>
                  <input
                    type="color"
                    value={value}
                    onChange={(e) => handleColorChange(key, e.target.value)}
                  />
                </div>


              </div>
            ))}

            <Button style={{ width: "7%", float: "right", marginRight: "3%", marginBottom: "3%", marginTop: "2%" }} color="primary" onClick={() => handleSubmit()}>
              {"Save"}
            </Button>

          </div>

        </Card>

        <Card>
          <div>
            <div style={{ padding: "20px" }}>

              <Button style={{ height: "10%", float: "right", }} color="primary"
                onClick={() =>
                  // addDropdown()
                  addFontDisplayModel()
                }
              >{"Add"}</Button>
            </div>

          </div>
          <div style={{ display: "flex", justifyContent: "space-between" }}>


            <div style={{ padding: "10px" }}>
              <h3>Android Fonts:</h3>
              {themeData && Object.entries(themeData?.fonts?.android)?.map(([key, value]) => (
                <div key={key} style={{ padding: 20, display: "flex", justifyContent: 'space-between', }}>
                  <span style={{ padding: 8 }}>{key}: </span>
                  <input

                    type="number"
                    value={value}
                    onChange={(e) => handleFontChange('android', key, Number(e.target.value))}
                  />
                </div>
              ))}


            </div>
            <div>

              <h3>iOS Fonts:</h3>
              {themeData && Object.entries(themeData?.fonts?.ios)?.map(([key, value]) => (
                <div key={key} style={{ padding: 20, display: "flex", justifyContent: 'space-between', }} >
                  <span style={{ padding: 8 }}>{key}: </span>
                  <input

                    type="number"
                    value={value}
                    onChange={(e) => handleFontChange('ios', key, Number(e.target.value))}
                  />
                </div>
              ))}


            </div>
          </div>
          <div style={{ float: "right" }}>


            <Button style={{ height: "10%", marginTop: "2%", float: "right", width: "7%", marginRight: "3%", marginBottom: "3%" }} color="primary"
              onClick={() => handleSubmit()}

            >{"Save"}</Button>
          </div>

        </Card>




      </div>







      <Row>
        <Col sm="12">
          <Card>
            <Modal isOpen={addColor} toggle={() => addColorDisplayModel()}>
              <ModalHeader toggle={() => addColorDisplayModel()}>
                {"Add Colrs"}
              </ModalHeader>
              <ModalBody>
                <Form>
                  <FormGroup>
                    {/* <Label className="col-form-label" for="recipient-name">{"Title:"}</Label> */}

                    <div style={{ display: "flex", marginTop: "70px", justifyContent: "space-between", marginLeft: "10%", marginRight: "10%" }}>
                      <div>

                        {/* <TextField
              value={key}
              onChange={(e) => setKey(e.target.value)}
              id="outlined-read-only-input"
              label="Type...."
              defaultValue="Hello World"
              InputProps={{
                readOnly: true,
              }}
            /> */}

                        <input
                          // type="color"
                          value={key}
                          onChange={(e) => setKey(e.target.value)}
                        />
                      </div>
                      <span>&nbsp;&nbsp;:&nbsp;&nbsp;</span>

                      <div>


                        <input

                          type="color"
                          value={color}
                          onChange={(e) => setColor(e.target.value)}
                        />
                      </div>
                      {/* <button 
               style={{marginLeft:"20px"}} onClick={() => handleColor()}>
                add
              </button> */}



                      <div onClick={() => handleColor()} style={{ borderWidth: 1, border: 1, borderColor: "blue", backgroundColor: "blue", transition: "0.5 ease", color: "white", padding: 8, width: 70, borderRadius: 10, height: 30, float: "right", cursor: "pointer", marginLeft: "20px" }}>
                        <h6 style={{ color: "white", textAlign: "center" }}>Add</h6>
                      </div>

                    </div>

                  </FormGroup>

                </Form>
              </ModalBody>
              <ModalFooter>
                <Button color="secondary" onClick={() => addColorDisplayModel()}>
                  {"Close"}
                </Button>
                {open && <Button color="primary" onClick={() => handleSubmit()}>
                  {"Save"}
                </Button>}
              </ModalFooter>
              {/* <div onClick={handleSubmit} style={{ borderWidth: 1, border: 1, borderColor: "blue", backgroundColor: "blue", transition: "0.5 ease", color: "white", padding: 10, width: "20%", borderRadius: 10, height: 40, cursor: "pointer" }}>
            <h6 style={{ color: "white", textAlign: "center" }}>Submit</h6>
          </div> */}
            </Modal>


          </Card>
        </Col>
      </Row>


      <Row>
        <Col sm="12">
          <Card>
            <Modal isOpen={openAddFont} toggle={() => addFontDisplayModel()}>
              <ModalHeader toggle={() => addFontDisplayModel()}>
                {"Add font"}
              </ModalHeader>
              <ModalBody>
                <Form>
                  <FormGroup>
                    <div >
                      <Label className="col-form-label" for="recipient-name">{"Name:"}   <input type="text" value={font} onChange={(e) => setFont(e.target.value)} />
                      </Label>

                      <div style={{ marginTop: "10px", }}>

                        <div>



                        </div>


                        <div style={{ marginLeft: "30px" }}>


  
                        </div>

                        <Label className="col-form-label" for="recipient-name">{"Font Size:"} <input type="number" value={number} onChange={(e) => setNumber(Number(e.target.value))} />
                        </Label>


             

                        <div onClick={() => fontHandler('android', 'ios')} style={{ borderWidth: 1, border: 1, borderColor: "blue", backgroundColor: "blue", transition: "0.5 ease", color: "white", padding: 8, width: 70, borderRadius: 10, height: 30, float: "right", cursor: "pointer", marginLeft: "20px" }}>
                          <h6 style={{ color: "white", textAlign: "center" }}>Add</h6>
                        </div>

          
                      </div>
                    </div>

                  </FormGroup>

                </Form>
              </ModalBody>

            </Modal>


          </Card>
        </Col>
      </Row>








    </Fragment>
  )
}
export default EditTheme;