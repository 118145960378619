import React, { Fragment, useState } from "react";
import { useEffect } from "react";
import adminServices from "../../api/adminService";
import {
  Row,
  Col,
  Card,
  CardBody,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Input, Form,

} from "reactstrap";
import { Container } from "react-bootstrap";
import DataTable from "react-data-table-component";
import { useLocation, useNavigate } from "react-router";
import moment from "moment/moment";
import Breadcrumb from "../../layout/breadcrumb";


const ReservedList = () => {
  const location = useLocation();

  const [loading, setLoading] = useState(true);
  const [VaryingContentone, setVaryingContentone] = useState(false);

  const [data, setData] = useState([]);
  const [showQuotation, setShowQuotation] = useState(false);
  const [quotation, setQuotation] = useState({});


  const history = useNavigate();

  const booking = location.state;
  const ModalToggle = () => setShowQuotation(!showQuotation);



  useEffect(() => {
    async function getBookingsData() {
      let qryString = "";
      if (booking) {
        qryString = new URLSearchParams({
          reservation_id: booking._id,
        }).toString();
      }
      console.log(qryString)
      const res = await adminServices.getBookings(qryString);
      console.log(res)
      setLoading(false);
      if (res.status) {
        setData(res.data);
      } else {
        setData([]);
      }

    }
    getBookingsData();
  }, [booking]);
  const onViewNavigate = (row) => {
    history(`${process.env.PUBLIC_URL}/app/bookingDetails/${row._id} `, { state: row });


  };

  const showQuoteOptions = async () => {
    // const res = await adminServices.getEnquriesForQuotation(booking._id);

    setShowQuotation(true);
    // setAcceptedEnq(res.data);
  }
  const saveQuotation = async () => {
    // let ids = data.map((d) => d._id);

    // const enqids = {
    //   ...quotation,
    //   enquries: ids,
    //   user_id: booking.user_id,
    // };
    // const res = await adminServices.saveQuotation(booking._id, enqids);
    // if (res.status) {
    //   ModalToggle();
    // } else {
    //   console.log("XJKK", res);
    // }
  };
  const onChangeText = (event, key) => {
    setQuotation((data) => {
      return { ...data, [key]: event.target.value };
    });
  };


  const tableData = {
    name: {
      name: "Title",
      selector: (row) => row.equipment_id.title,
      sortable: true,
    },
    location: {
      name: "Location",
      selector: (row) => row.address?.type,
      sortable: true,
    },
    rentalPeriodFrom: {
      name: "Rental period(from)",
      selector: (row) => {
        return moment(row.rental_period[0]).format("DD-MM-YYYY");
      },
      sortable: true,
      center: true,
    },
    rentalPeriodTo: {
      name: "Rental period(to)",
      selector: (row) => {
        return moment(row.rental_period[1]).format("DD-MM-YYYY");
      },
      sortable: true,
      center: true,
    },
    operators: {
      name: "Operators",
      selector: (row) => row.operators,
      sortable: true,
    },
    quantity: {
      name: "Quantity",
      selector: (row) => row.quantity,
      sortable: true,
    },
    helpers: {
      name: "Helpers",
      selector: (row) => row.helpers,
      sortable: true,
    },

    shift: {
      name: "Shift",
      selector: (row) => row.shift,
      sortable: true,
    },
    status: {
      name: "Status",
      selector: (row) => row.status,
      sortable: true,
    },
    view: {
      name: "View",
      selector: (row) => {
        return (
          <div>
            <Button color="primary" onClick={() => onViewNavigate(row)}>
              View
            </Button>
          </div>
        );
      },
      sortable: true,
      center: true,
    },
  };


  var conf = localStorage.getItem("reservedBookingConf");
  console.log("CONF", conf);

  if (conf) {
    conf = JSON.parse(conf);
  } else {
    conf = [
      { value: 'name', title: "Name", selected: true },
      { value: 'location', title: "Location", selected: true },
      { value: 'rentalPeriodfrom', title: "Rental Period From", selected: false },
      { value: 'rentalPeriodTo', title: "Rental Period To", selected: false },
      { value: 'operators', title: "Pperators", selected: false },
      { value: 'quantity', title: "Quantity", selected: true },
      { value: 'helpers', title: "Helpers", selected: false },
      { value: 'shift', title: "Shift", selected: false },
      { value: 'status', title: "Status", selected: false },
      { value: 'view', title: "View", selected: true }
    ];
  }

  const [filter, setFilter] = useState(conf);

  const content = filter && filter?.filter((d) => d.selected)?.map((d1) => d1.value);

  const tableColumns = [];

  Object.keys(tableData).map((d) => {

    if (content.includes(d)) {

      tableColumns.push(tableData[d]);
    }
  })


  const obj = data && data.map(s => Object.keys(s))



  const openPopHandler = () => {
    setVaryingContentone(!VaryingContentone)
    setFilter(filter)
  }

  const showhideHandler = (values, checked) => {

    const index = filter.findIndex(val => val.value == values.value);
    const j = JSON.parse(JSON.stringify(filter));

    j[index] = { ...values, selected: checked };
    setFilter(j);
  }
  return (
    <Fragment>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "flex-end",
        }}
      >
        <Breadcrumb parent="Table" title="Bookings List"        renderOptions={() => {
                return (
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "flex-end",
                    }}
                  >
                    <Button style={{ marginLeft: "10px" }} onClick={() => openPopHandler()}>Filter</Button>
                    
                  </div>
                );
              }}/>
        <Button
          style={{
            marginRight: "2%",
            width: "17%",
            height: "2%",
            marginTop: "2%",
          }}
          onClick={() => showQuoteOptions()}
        >
          {"Generate Quotation"}
        </Button>
        

      </div>

      <Container fluid={true}>
        <Row>
          <Col sm="12">
            <Card>
              {/*<CardHeader style={{ display: "flex", flexDirection: "row", justifyContent: "flex-end" }}>

                                <Button color="primary" onClick={() => history(`${process.env.PUBLIC_URL}/app/equip/${layout}`, { state: { category: categoryData, isEdit: false } })}>{"Add"}</Button>

    </CardHeader>*/}


              <Modal isOpen={VaryingContentone} style={{ width: "30%" }}
                onClosed={() => localStorage.setItem("reservedBookingConf", JSON.stringify(filter))}
                toggle={() => setVaryingContentone(!VaryingContentone)}
              >
                <ModalHeader
                  toggle={() => setVaryingContentone(!VaryingContentone)}
                >
                  {"Display"}
                </ModalHeader>
                <ModalBody>
                  <Form>

                    {filter.map(i => {
                      return (<div style={{ margin: "20px" }}>
                        <input type="checkbox" defaultChecked={i.selected} value={i.selected} name={i} onChange={(e) => showhideHandler(i, e.target.checked)} />
                        <span style={{ marginLeft: "30px" }}>{i.title}</span>
                      </div>)
                    })}


                  </Form>
                </ModalBody>
              </Modal>

              <CardBody>
                <DataTable
                  data={data}
                  columns={tableColumns}
                  // selectableRows
                  // contextActions={contextActions}
                  // onSelectedRowsChange={handleRowSelected}
                  center={true}
                />
              </CardBody>
            </Card>
          </Col>
          <Modal isOpen={showQuotation} toggle={ModalToggle}>
            <ModalHeader toggle={ModalToggle}>{"New Quotation"}</ModalHeader>
            <ModalBody>
              <Container fluid={true} className="bd-example-row">
                <Row>
                  <Col md="4">Quote Amount</Col>
                  <Col md="6">
                    <Input
                      onChange={(event) => onChangeText(event, "amount")}
                      value={quotation.amount}
                      placeholder="Amount"
                    />
                  </Col>
                </Row>

                <Row className="mt-3">
                  <Col md="4">Fake Price</Col>
                  <Col md="6">
                    <Input
                      onChange={(event) => onChangeText(event, "fake_price")}
                      value={quotation.fake_price}
                      placeholder="Fake price"
                    />
                  </Col>
                </Row>
                <Row className="mt-3">
                  <Col md="4">Quantity</Col>
                  <Col md="6">
                    <Input
                      onChange={(event) => onChangeText(event, "quantity")}
                      value={quotation.quantity}
                      placeholder="Quantity"
                    />
                  </Col>
                </Row>
                <Row className="mt-3">
                  <Col md="12">Terms</Col>
                  <Col md="12" className="ms-auto">
                    <Input
                      onChange={(event) => onChangeText(event, "terms")}
                      value={quotation.terms}
                      type="textarea"
                      placeholder="Terms"
                    />
                  </Col>
                </Row>

                <Row className="mt-3">
                  <Col md="12">Remarks</Col>
                  <Col md="12" className="ms-auto">
                    <Input
                      onChange={(event) => onChangeText(event, "remarks")}
                      // value={quotation.remarks}
                      type="textarea"
                      placeholder="Remarks"
                    />
                  </Col>
                </Row>
              </Container>
            </ModalBody>
            <ModalFooter>
              <Button color="secondary" onClick={ModalToggle}>
                {"Close"}
              </Button>
              <Button color="primary" onClick={() => saveQuotation()}>
                {"SaveChanges"}
              </Button>
            </ModalFooter>
          </Modal>
        </Row>
      </Container>
    </Fragment>
  );
};
export default ReservedList;
