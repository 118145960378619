import React, { Fragment, useState, Suspense } from "react";
import DataTable from "react-data-table-component";
import Breadcrumb from "../../layout/breadcrumb";
import {
  Row, Col, Card, CardBody, CardHeader, Container, Button, Modal, ModalBody, ModalFooter, Form,
  ModalHeader, Label, Input, FormGroup, FormFeedback
} from "reactstrap";
import { useEffect } from "react";
import adminServices from "../../api/adminService";
import { toast } from "react-toastify";
import { API_BASE_URL } from "../../api/url";
import SweetAlert from 'sweetalert2'
import { classes } from "../../data/layouts";
import { useNavigate } from "react-router";
import moment from "moment/moment";
import Loading from "./Loading";


const Category = () => {


  const [data, setData] = useState("");
  const [VaryingContentone, setVaryingContentone] = useState(false);
  const [VaryingContent, setVaryingContent] = useState(false);

  const [selectedItem, setSelectedItem] = useState("");
  const [title, setTitle] = useState("");
  const [error, setError] = useState("");
  const [description, setDescription] = useState("");
  const [selectedIcon, SetSelectedIcon] = useState("");
  const [uploadImg, setUploadImg] = useState(false);
  const [loader, setLoader] = useState(true)
  const defaultLayoutObj = classes.find(item => Object.values(item).pop(1) === 'compact-wrapper');
  const layout = localStorage.getItem('layout') || Object.keys(defaultLayoutObj).pop();

  const history = useNavigate();


  const displayModel = (row) => {
    if (row) {
      // setUploadImg(uploadImg)
      // SetSelectedIcon(API_BASE_URL + row.icon)
      SetSelectedIcon( row.icon )
      setSelectedItem(row);
      setTitle(row.title);
      setDescription(row.description);
      setVaryingContentone(!VaryingContentone);
    } else {
      setVaryingContentone(!VaryingContentone);
      SetSelectedIcon("");
      setTitle("");
      setDescription("");
      // setSelectedItem("")
    }

  }

  async function getCategory() {
    setLoader(true);
    const res = await adminServices.getCategory();
    if (res.status) {
      setLoader(false);
      setData(res.data);
    }

  }
  useEffect(() => {
    getCategory()

  }, [0])


  // if(load === true){
  //   return (
  //     <div >
  //       <span >
  //         <Loading />
  //       </span>
  //     </div>
  //   );
  // }



  console.log(data)

  const removeCategory = (row) => {


    SweetAlert.fire({
      title: 'Are you sure?',
      text: "Once deleted, you will not be able to recover this user!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Ok',
      cancelButtonText: 'cancel',
      reverseButtons: true
    }).then((result) => {
      if (result.value) {
        adminServices.updateCategory({ ...row, status: false }, row._id,)
        SweetAlert.fire(
          'Deleted!',
          'Your Category has been deleted.',
          'success'
        )
        getCategory();
      }
      else {
        SweetAlert.fire(
          'Your file is safe!'
        )
      }
      getCategory();

    })
    getCategory();

  }

  const onViewNavigate = (row) => {
    history(`${process.env.PUBLIC_URL}/app/equipments/${row._id}`, { state: row });
  }

  const tableData = {
  id :  {
      name: "ID",
      selector: (row) => row._id,
      sortable: true,
    },
  name :  {
      name: 'Title',
      selector: row => row.title,
      sortable: true,

    },
   createdat : {
      name: "Created Date",
      selector: (row) => {
        return moment(row.rental_period).format("DD-MM-YYYY");
      },
      sortable: true,
      center: true,
    },
   icon : {
      name: 'Icon',
      selector: row => {
        if (row.icon) {
          return <img
            width={50}
            height={50}
            src={API_BASE_URL + row.icon }
          />
        } else {
          return <img
            width={50}
            height={50}
            src={require("../../assets/images/Icons/logo.png")}
          />
        }

      },
      sortable: true,
    },

    action :{
      name: 'Action',
      selector: row => {
        return <div>
          <span>  <i
            className="fa fa-eye"
            style={{
              cursor: "pointer",
              width: 35,
              fontSize: 16,
              padding: 11,
              color: "rgb(40, 167, 69)",
            }}
            onClick={() => onViewNavigate(row)}
          ></i></span>
          <span><i className="fa fa-pencil" style={{ width: 35, fontSize: 16, padding: 11, color: 'rgb(40, 167, 69)' }}
            onClick={() => displayModel(row)} ></i></span>
          <span><i className="fa fa-trash" style={{ width: 35, fontSize: 16, padding: 11, color: '#e4566e' }}
            onClick={() => removeCategory(row)}


          ></i></span>
        </div>
      },
      sortable: true,
      center: true,
    },
  }

  var conf = localStorage.getItem("categoryConf");
  console.log("CONF", conf);

  if (conf) {
    conf = JSON.parse(conf);
  } else {
    conf = [
      { value: 'id', title: "Id", selected: true },
      { value: 'name', title: "Name", selected: true },
      { value: 'createdat', title: "Created At", selected: false },
      { value: 'icon', title: "Icon", selected: false },
      { value: 'action', title: "Action", selected: true },

    ];
  }

  const [filter, setFilter] = useState(conf);

  const content = filter && filter?.filter((d) => d.selected)?.map((d1) => d1.value);

  const tableColumns = [];

  Object.keys(tableData).map((d) => {

    if (content.includes(d)) {

      tableColumns.push(tableData[d]);
    }
  })


  const obj = data && data.map(s => Object.keys(s))



  const openPopHandler = () => {
    setVaryingContent(!VaryingContent)
    setFilter(filter)
  }

  const showhideHandler = (values, checked) => {

    const index = filter.findIndex(val => val.value == values.value);
    const j = JSON.parse(JSON.stringify(filter));

    j[index] = { ...values, selected: checked };
    setFilter(j);
  }

  const onSave = async () => {


    if (!title) {
      setError("Please enter the Title");
      return;
    }

    const formData = new FormData();
    formData.append("title", title);
    formData.append("icon", selectedIcon);
    formData.append("description", description);
    formData.append("status", true);



    if (selectedItem) {

    if (!title) {
      setError("Please enter the Title");
      return;
    }

      const res = await adminServices.updateCategory(
        formData, selectedItem._id
      );
      console.log(res)
      if (res.status) {


        toast(res.message);
        getCategory();
        SetSelectedIcon("");
        setTitle("");
        setDescription("");
        displayModel();
      } else {
        toast.error(res.message);
      }

    } else {
      const res = await adminServices.addCategory(formData);


      if (res.status) {
        toast(res.message);
        getCategory();
        SetSelectedIcon("");
        setTitle("");
        setDescription("");
        displayModel()

      } else {
        toast.error(res.message)
      }
    }

  }

  let icon = ""

  if (selectedItem) {
    if (selectedIcon && typeof selectedIcon != 'string') {
      icon = URL.createObjectURL(selectedIcon);
    } else {
      icon = selectedIcon;
    }
  } else if (selectedIcon) {
    icon = URL.createObjectURL(selectedIcon);
  }


  // if (selectedIcon) {
  //   console.log("229")
  //   if (selectedItem) {
  //     icon = selectedIcon;
  //     console.log("line 231")
  //   } else {
  //     icon = URL.createObjectURL(selectedIcon);
  //     console.log("line 234")
  //   }
  // }

  // if (uploadImg) {
  //   // if(selectedIcon){
  //     icon = URL.createObjectURL(selectedIcon);
  //   // }
  // }





  return (
    <Fragment>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "flex-end",
        }}
      >
        <Breadcrumb parent="Category" title="Category" 
        
        buttonOptions={() => {
          return (
            <div
           style={{
             display: "flex",
             flexDirection: "row",
             justifyContent: "flex-end",
           }}
           >
           <Button style={{ marginLeft: "10px" }} onClick={() => openPopHandler()}>Filter</Button>
           
         </div>
       );
     }}
        
        />

        <Button
          style={{ height: "10%", marginTop: "2%" }}
          color="primary"
          onClick={() => displayModel()}
        >
          {"Add"}
        </Button>
      </div>
      {loader ? <Loading /> : <Container fluid={true}>
        <Row>
          <Col sm="12">
            <Card>
              <Modal isOpen={VaryingContentone} toggle={() => displayModel()}>
                <ModalHeader toggle={() => displayModel()}>
                  {"Category"}
                </ModalHeader>
                <ModalBody>
                  <Form>
                    <FormGroup>
                      <Label className="col-form-label" for="recipient-name">{"Title:"}</Label>
                      <Input className="form-control"
                        type="text" value={title} defaultValue={""}
                        onChange={(e) => {
                          setTitle(e.target.value)
                          setError("")
                        }}
                        valid={error}
                        invalid={error}
                      />

                      <FormFeedback>{error}</FormFeedback>

                    </FormGroup>
                    <FormGroup>
                      <Label className="col-form-label" for="message-text">{"Description:"}</Label>
                      <Input type="textarea" className="form-control" id="message-text"
                        value={description} defaultValue={""}
                        onChange={(event) => setDescription(event.target.value)}></Input>
                    </FormGroup>
                    <FormGroup>
                      <Label className="col-form-label" for="recipient-name">{"Icon:"}</Label>


                      <img
                        width={100}
                        height={100}
                        style={{ marginLeft: 10 }}
                        src={icon}

                      />

                      <input
                        style={{ marginTop: 10 }}
                        onChange={(e) => {
                          // setUploadImg(true);
                          SetSelectedIcon(e.target.files[0]);

                        }}
                        // value={selectedIcon}
                        defaultValue={""}
                        accept="image/*"
                        className="form-control" type="file" />
                    </FormGroup>

                  </Form>
                </ModalBody>
                <ModalFooter>
                  <Button color="secondary" onClick={() => displayModel()}>
                    {"Close"}
                  </Button>
                  <Button color="primary" onClick={() => onSave()}>
                    {"Save"}
                  </Button>
                </ModalFooter>
              </Modal>


              <Modal isOpen={VaryingContent} style={{ width: "30%" }}
                onClosed={() => localStorage.setItem("categoryConf", JSON.stringify(filter))}
                toggle={() => setVaryingContent(!VaryingContent)}
              >
                <ModalHeader
                toggle={() => setVaryingContent(!VaryingContent)}
                >
                  {"Display"}
                </ModalHeader>
                <ModalBody>
                  <Form>

                    {filter.map(i => {
                      return (<div style={{ margin: "20px" }}>
                        <input type="checkbox" defaultChecked={i.selected} value={i.selected} name={i} onChange={(e) => showhideHandler(i, e.target.checked)} />
                        <span style={{ marginLeft: "30px" }}>{i.title}</span>
                      </div>)
                    })}


                  </Form>
                </ModalBody>
              </Modal>


              <CardBody>
                <DataTable data={data} columns={tableColumns} center={true} />
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>}
    </Fragment>
  );
}

export default Category;