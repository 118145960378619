
import { FaLocationArrow, FaTimes } from 'react-icons/fa'
import { Fragment } from 'react';
import { toast } from "react-toastify";
import { Button, Container, Row, Col, Breadcrumb } from "reactstrap";
import TextField from '@material-ui/core/TextField';
import { RiCloseFill } from "react-icons/ri"

import {
  useJsApiLoader,
  GoogleMap,
  Marker,
  Autocomplete,
  DirectionsRenderer,
} from '@react-google-maps/api'
import { useRef, useState, useCallback, useEffect } from 'react'
import axios from 'axios'
import { useLocation, useNavigate } from "react-router";
import adminServices from "../../api/adminService";


const center = { lat: 48.8584, lng: 2.2945 }


function Address() {
  const { isLoaded } = useJsApiLoader({
    // googleMapsApiKey: process.env.AIzaSyAe344YEYJJivAJ5JcBMYaT1_XW4du1bk4,
    googleMapsApiKey: "AIzaSyAe344YEYJJivAJ5JcBMYaT1_XW4du1bk4",
    libraries: ['places'],
  })

  const [map, setMap] = useState(/** @type google.maps.Map */(null))

  const [pincode, setPinCode] = useState("");
  const [data, setData] = useState({})
  const [state, setState] = useState("")
  const [autocomplete, setAutocomplete] = useState(null);
  const [card, setCard] = useState([])
  const location = useLocation();
  const history = useNavigate()
  const userId = location.state;


  const originRef = useRef()
  const destiantionRef = useRef()
  const [mapCenter, setMapCenter] = useState({
    lat: 17.387140,
    lng: 78.491684
  });
  const containerStyle = {
    width: '100%',
    height: '100%'
  };

  if (!isLoaded) {
    return <p>Loading... </p>
  }




  // const handleSearch = () => {
  //   if (map && originRef) {
  //     const geocoder = new window.google.maps.Geocoder();
  //     setState(originRef.current.value)
  //     geocoder.geocode({ address: originRef.current.value }, (results, status) => {
  //       if (status === 'OK' && results && results[0]) {
  //         const location = results[0].geometry.location;
  //         setMapCenter({
  //           lat: location.lat(),
  //           lng: location.lng()
  //         });
  //         map.panTo(location);
  //       }
  //     });
  //   }
  // };

  const handleSubmit = async () => {
    const res = await adminServices.addAddress(userId, data);
    if (res.status) {
      toast(res.message)
      history(-1)
    } else {
      toast(res.message.message)
    }
  }
  console.log("card", card)
  const fetchAddress = async () => {
    const url = `https://maps.googleapis.com/maps/api/geocode/json?latlng=${[mapCenter.lat, mapCenter.lng]}&key=AIzaSyAe344YEYJJivAJ5JcBMYaT1_XW4du1bk4`;
    await axios.get(url).then(response => {
      console.log(response)
      // setCard(response.data.results[0])
      try {
        var name, city, postal_code, state, country = "", latlag, plus_code;
        const address = response.data.results[0].formatted_address;

        for (var i = 0; i < response?.data?.results[0].address_components.length; i++) {

          if (response.data.results[0].address_components[i].types[0].includes("locality")) {
            name = response.data.results[0].address_components[i].long_name;
          }
          if (response.data.results[0].address_components[i].types[0].includes("administrative_area_level_3")) {
            city = response.data.results[0].address_components[i].long_name;
          }
          if (response.data.results[0].address_components[i].types[0].includes('administrative_area_level_1')) {
            state = response.data.results[0].address_components[i].long_name;
          }
          if (response.data.results[0].address_components[i].types[0].includes('country')) {
            country = response.data.results[0].address_components[i].long_name;
          }
          if (response.data.results[0].address_components[i].types[0].includes('postal_code')) {
            postal_code = response.data.results[0].address_components[i].long_name
          }
          if (response.data.results[0].address_components[i].types.includes("plus_code")) {
            plus_code = response.data.results[0].address_components[i].long_name
          }
        }

        let add = {
          name: name,
          city: city,
          state: state,
          country: country,
          complete_address: address.replace(plus_code + ",", ""),

          pin_code: postal_code,
          location: {
            type: "Point",
            coordinates: [mapCenter.lat, mapCenter.lng]
          },
          type: ""
        };


        setData(add);
        setPinCode(postal_code)
      } catch (e) {
        console.error("error catch block======>>>>>", e);
      }
      // setData(response.data.results[0])
    }).catch(error => {
      console.log("ERROR", error)
    })
  }

  const onLoad = (mapInstance) => {
    setMap(mapInstance);
  };

  const onAutocompleteLoad = (autocomplete) => {
    console.log(autocomplete)
    setAutocomplete(autocomplete);
  };

  const onPlaceChanged = () => {
    if (autocomplete !== null) {
      const place = autocomplete.getPlace();
      console.log(place)
      if (place.geometry) {
        const lat = place.geometry.location.lat();
        const lng = place.geometry.location.lng();
        setMapCenter({ lat, lng });
        map.panTo({ lat, lng });
      }
    }
    fetchAddress()
  };

  return (
    <Fragment>
      <div style={{ display: "flex", flexDirection: "row", justifyContent: "flex-end" }}>

        <Breadcrumb title="Add Address" />
        {/* <Button style={{ width: "8%", height: "10%", marginTop: "2%" }} color="primary" onClick={() =>
          history(`${process.env.PUBLIC_URL}/app/user/${layout}`)
        }>{"Add"}</Button> */}
      </div>

      <div style={{ backgroundColor: '#fff' }} >
        <Container fluid={true}>
          <Row flexGrow={1}>
            <Autocomplete onLoad={onAutocompleteLoad} onPlaceChanged={onPlaceChanged}>
              <TextField
                type='text' placeholder='Search location' style={{ width: "50%", margin: "10px" }} ref={originRef}
                label="Search"
              />
            </Autocomplete>
          </Row>


        </Container>
        <Container
          fluid={true}
          style={{ width: '100%', flexDirection: 'row', height: '100vh' }}
        >
          <Row >
            <Col sm="8">
              <GoogleMap
                // style={{ position: "absolute" }}
                mapContainerStyle={containerStyle}
                center={mapCenter}
                zoom={20}
                onLoad={map => setMap(map)}
                onUnmount={map => {
                  // do your stuff before map is unmounted
                }}
                onClick={(e) => {
                  const latLong = [e.latLng.lat(), e.latLng.lng()];
                  setMapCenter({ lat: e.latLng.lat(), lng: e.latLng.lng() });
                  fetchAddress()
                }}
              >
                <Marker position={mapCenter} />
              </GoogleMap>
            </Col>

            <Col sm="4">
              <div style={{
                backgroundColor: "rgba(255, 255, 255, 0.2)", /* Adjust the last value (0.2) for opacity */
                border: "1px solid #ccc",
                padding: "20px",
                borderRadius: "8px",
                boxShadow: " 0px 4px 6px rgba(0, 0, 0, 0.1)",
                width: "380px",
                boxShadow: '1px 1px 9px #0d47a1',

                // margin: "auto",
              }} >
                <h5 style={{ textDecoration: "underline" }}>Address</h5>
                <div>
                  <p style={{ fontSize: "16px", fontStyle: "italic" }}> city: {data.city}</p>
                  <p style={{ fontSize: "16px", fontStyle: "italic" }}> State: {data.state}</p>
                  <p style={{ fontSize: "16px", fontStyle: "italic" }}> Country: {data.country}</p>
                  <p style={{ fontSize: "16px", fontStyle: "italic" }}> PIN Code : {data.pin_code}</p>
                </div>
                <div>
                  {!pincode && <div>

                    pincode :

                    <TextField
                      // mode="outlined"
                      value={pincode}
                      // onChange={e => setPinCode(e.target.value)
                      onChange={(e) => setData({ ...data, "pin_code": e.target.value })}

                      label="Pin Code"
                      type='text' placeholder='Enter pincode' style={{ width: "100%", marginBottom: "10px" }}
                    />

                  </div>
                  }
                </div>
                <div>

                  <TextField
                    // mode="outlined"
                    value={data.type}
                    // onChange={e => setPinCode(e.target.value)
                    onChange={(e) => setData({ ...data, "type": e.target.value })}

                    label="Type"
                    type='text' placeholder='Site, Garage' style={{ width: "100%", marginBottom: "10px" }}
                  />

                  {<textarea
                    value={data.complete_address}
                    style={{ borderRadius: "5px", marginTop: "20px", marginBottom: "20px" }}
                    rows={5}
                    cols={35}
                    onChange={(e) => setData({ ...data, complete_address: e.target.value })}

                  />}
                </div>

                <Button color="primary" onClick={() => handleSubmit()}>
                  {"Save"}
                </Button>
              </div>
            </Col>
          </Row>



        </Container>
        <div>

        </div>
      </div>
    </Fragment>
  )
}

export default Address
